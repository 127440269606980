const dayjs = require("dayjs")
const utc = require('dayjs/plugin/utc')
const timezone = require('dayjs/plugin/timezone')
dayjs.extend(utc)
dayjs.extend(timezone)

/** Shared constants here */
const ONE_SECOND = 1000
exports.ONE_SECOND = ONE_SECOND
const ONE_MINUTE = 60 * ONE_SECOND
exports.ONE_MINUTE = ONE_MINUTE
const ONE_HOUR = 60 * ONE_MINUTE
exports.ONE_HOUR = ONE_HOUR
const ONE_DAY = 24 * ONE_HOUR
exports.ONE_DAY = ONE_DAY
const ONE_WEEK = 7 * ONE_DAY
exports.ONE_WEEK = ONE_WEEK

exports.DEFAULT_TIMER_LENGTH_MS = ONE_MINUTE * 25
exports.JOIN_SESSION_GRACE_PERIOD = ONE_MINUTE * 10
exports.JOIN_SESSION_GRACE_PERIOD_NEW_USER = ONE_MINUTE * 30
exports.JOIN_SESSION_NEW_USER_WAIT_PERIOD = ONE_MINUTE * 5
exports.TRIAL_EXTENSION_LENGTH = ONE_WEEK
exports.TRIAL_EXTENSION_SECOND_CHANGE_COOL_OFF = ONE_WEEK
exports.NUM_NEW_USER_GENERAL_SESSION_ADDITIONAL_SEATS = 1
exports.JOIN_SESSION_BEFORE_START_PERIOD_MIN = 15;
exports.JOIN_SESSION_BEFORE_START_PERIOD_MS = ONE_MINUTE * exports.JOIN_SESSION_BEFORE_START_PERIOD_MIN
exports.FIRST_TIMER_MAX_CHAT_ONLY_DURATION_MIN = 60;


const DEFAULT_TIMEZONE = 'America/Los_Angeles'
exports.DEFAULT_TIMEZONE = DEFAULT_TIMEZONE

const HostStatus = {
  // Not yet onboarded
  NOT_QUALIFIED: 'not_qualified', // < 10 sessions
  QUALIFIED: 'qualified', // hit 10 sessions, but haven't filled out form
  FILLED_OUT_FORM: 'filled_out_form', // filled out host interest form
  FILLED_OUT_FORM_IN_APP: 'filled_out_form_in_app', // started the in-app host upgrade process
  APPROVED: 'approved', // filled out host interest form and got approved
  STARTED_HOST_UPGRADE: 'started_host_upgrade', // started the in-app host upgrade process
  NOT_NOW: 'not_now', // hit 10 sessions, but declined to fill out form/doesn't want to see host prompts
  ISSUE: 'onboarding_issue', // Ran into an issue during onboarding

  // Onboarded and active
  ACTIVE: 'active', // active host
  PAUSED: 'paused', // explicitly asked to pause hosting for a specific period of time
  DORMANT: 'dormant', // active host, but hasn't hosted in last 2 weeks

  // Got onboarded but no longer active
  INACTIVE: 'inactive', // Hasn't hosted in >4 weeks but hasn't explicitly asked to stop hosting forever
  CHURNED: 'churned', // unsubscribed from Flow Club as members so can no longer host
  OFFBOARDED: 'offboarded', // Still members but explicitly told us they don't want to host or we've asked them to stop hosting
}

exports.HostStatus = HostStatus


const HOST_TIME_UNTIL_DORMANT = 1000 * 60 * 60 * 24 * 14 // 14 days
exports.HOST_TIME_UNTIL_DORMANT = HOST_TIME_UNTIL_DORMANT

const HOST_TIME_UNTIL_INACTIVE = 1000 * 60 * 60 * 24 * 28 // 28 days
exports.HOST_TIME_UNTIL_INACTIVE = HOST_TIME_UNTIL_INACTIVE

const HOST_ELIGIBILITY_REQUIRED_NUM_SESSIONS = 10 // require 10 sessions attended until a user can be a host
exports.HOST_ELIGIBILITY_REQUIRED_NUM_SESSIONS = HOST_ELIGIBILITY_REQUIRED_NUM_SESSIONS


const SubscriptionStatus = {
  ACTIVE: 'active', // actually from Stripe
  ACTIVE_COMPLIMENTARY: 'active - complimentary',
  ACTIVE_ANIMALZ: 'active - Animalz',
  CANCELED: 'canceled',
  INACTIVE: 'inactive', // Set when we changed someone's account over to a different userId
  PAST_DUE: 'past_due', // actualy from Stripe
  PAUSED: 'paused', // actually from Stripe
  ACTIVE_BUT_TRIALING: 'trialing', // Actually from Stripe, Trialing, but put in their card, so will be automatically billed when trial ends and should be active/considered a normal member
  TRIALING_NO_CARD: 'trialing - no card', // Default trialing state
  UNAUTHED_USER: 'unauthedUser', // User has not yet been authenticated in Firebase Auth,
  EXTREMELY_UNAUTHED_USER: 'extremely_unauthed_user', // User has not yet been authenticated in Firebase Auth, and has not yet signed up with an email or name

  // From stripe, but we should never update to these
  INCOMPLETE: 'incomplete', // actually from Stripe
  INCOMPLETE_EXPIRED: 'incomplete_expired', // actually from Stripe
}
exports.SubscriptionStatus = SubscriptionStatus

const SubscriptionStatusesToIgnore = new Set([
  SubscriptionStatus.INCOMPLETE,
  SubscriptionStatus.INCOMPLETE_EXPIRED,
])
exports.SubscriptionStatusesToIgnore = SubscriptionStatusesToIgnore

const SpaceEventTypes = {
  JOINED: "JOINED",
  STARTED_TIMER: "STARTED_TIMER",
  STARTED_BREAK: "STARTED_BREAK",
  COMPLETED_GOAL: "COMPLETED_GOAL",
  HIGH_FIVE: "HIGH_FIVE",
}

exports.SpaceEventTypes = SpaceEventTypes

const HighFiveableEvents = {
  "COMPLETED_GOAL": true,
  "HIGH_FIVE": true,
}
exports.HighFiveableEvents = HighFiveableEvents

// Also need to update the duration options for sessionDurationFilters in endpoints.ts since that only exists at compile
const DURATION_OPTIONS = [30, 60, 90, 120, 180]

exports.DURATION_OPTIONS = DURATION_OPTIONS

const TimeFilterOptions = {
  "Morning": {
    name: "Morning",
    icon: "🌅",
    description: "5AM - 12PM",
    start: 5 * ONE_HOUR,
    end: 12 * ONE_HOUR,
  },
  "Afternoon": {
    name: "Afternoon",
    icon: "☀️",
    description: "12PM - 6PM",
    start: 12 * ONE_HOUR,
    end: 18 * ONE_HOUR,
  },
  "Night": {
    name: "Night",
    icon: "🌃",
    description:
    "6PM onwards",
    start: 18 * ONE_HOUR,
    end: 24 * ONE_HOUR,
    nextDayEnd: 5 * ONE_HOUR,
  },
}
exports.TimeFilterOptions = TimeFilterOptions

// Eventually should also include all the simple themes, but for now
// Just chat-only and pomodoro
const showChatOnlyLabel = '⌨️ Chat-only'
const showNonChatOnlyLabel = '🗣️ Spoken'
const showBreakCheckInsLabel = '✅ Check-in during breaks'
const showNoBreakCheckInsLabel = '🕒 No Check-ins during breaks'
const showPomodoroLabel = '🍅 Pomodoro'
const showNonPomodoroLabel = '⏲️ Standard'

const ThemeFilterOptions = {
  "🗣 Sharing Protocol" : [
    {
      name: "showNonChatOnly",
      icon: "🗣️",
      label: showNonChatOnlyLabel,
      description: "Speak your goals aloud for accountability",
      field: "chatOnly",
      value: false,
    },
    {
      name: "showChatOnly",
      icon: "⌨️",
      label: showChatOnlyLabel,
      description: "Share goals in chat. No verbal sharing",
      field: "chatOnly",
      value: true,
    },
    {
      name: "showBreakCheckIns",
      icon: "✅",
      label: showBreakCheckInsLabel,
      description: "Check-in during breaks",
      field: "breakCheckIns",
      value: true,
    },
    {
      name: "showNoBreakCheckIns",
      icon: "🕒",
      label: showNoBreakCheckInsLabel,
      description: "No Check-in during breaks",
      field: "breakCheckIns",
      value: false,
    },
  ],
  "⏲ Timer Type": [
    {
      name: "showNonPomodoro",
      icon: "⏲️",
      label: showNonPomodoroLabel,
      description: "See agenda for structure",
      field: "pomodoro",
      value: false,
    },
    {
      name: "showPomodoro",
      icon: "🍅",
      label: showPomodoroLabel,
      description: "25/5 structure",
      field: "pomodoro",
      value: true,
    },
  ],
}
exports.ThemeFilterOptions = ThemeFilterOptions

const WeeklySessionGoalOptions = {
  'Opt-out': { name: 'Opt-out', hours: 0 },
  'Introductory': { name: 'Introductory', hours: 1 },
  'Practice': { name: 'Practice', hours: 3 },
  'Habit': { name: 'Habit', hours: 5 },
  'Committed': { name: 'Committed', hours: 10 },
  'Extra Credit': { name: 'Extra Credit', hours: 20 },
  'Virtuoso': { name: 'Virtuoso', hours: 40 },
}
exports.WeeklySessionGoalOptions = WeeklySessionGoalOptions

const marketingURL = 'https://www.flow.club'
exports.marketingURL = marketingURL

const MAKING_TIME_FOR_GOALS = {
  WORK: 'work',
  SCHOOL: 'school',
  MYSELF: 'myself',
  RELATIONSHIPS: 'relationships',
  OTHER: 'other',
}

exports.MAKING_TIME_FOR_GOALS = MAKING_TIME_FOR_GOALS

// Not being able to index these with [MAKING_TIME_FOR_GOALS.work] is really rough here

const makingTimeForGoals = [
  'work',
  'school',
  'myself',
  'relationships',
  'other',
]

exports.makingTimeForGoals = makingTimeForGoals

const makingTimeForGoalEmojis = {
  'work': '💼',
  'school': '🏫',
  'myself': '🧘',
  'relationships': '🏡',
}
exports.makingTimeForGoalEmojis = makingTimeForGoalEmojis

const goalToMakeTimeFor = {
  'work': 'work',
  'school': 'school',
  'myself': 'yourself',
  'relationships': 'your relationships',
  'other': 'anything',
}

exports.goalToMakeTimeFor = goalToMakeTimeFor


/**
 * Describes the action the user can currently take on the event.
 * WILL update with time, i.e. may be "host/future-session" for a future session
 * that the user is currently planning to host and will become "host/happening-now"
 * when the session is available to be started
 */
const USER_SESSION_STATUS = {
  PARTICIPANT_FUTURE_SESSION: 'participant/future-session',                       // JOINING          // they have a participant, they are booked and its in the future
  PARTICIPANT_HAPPENING_NOW: 'participant/happening-now',                         // JOIN THE SESSION // they have a participant, they are booked and it is currently happening
  PARTICIPANT_HAPPENING_NOW_CANCELABLE: 'participant/happening-now/cancelable',   // JOIN THE SESSION // they have a participant, they are booked and it is currently happening, but is still within the booking/cancelation grace period
  PARTICIPANT_PAST_SESSION: 'particpant/past-session',                            // MISSED IT        // they have a participant and the session is in the past, already happened
  JOINABLE_FUTURE_SESSION_REQUIRES_CONFIRMATION: 'joinable/future-session/require-confirmation', // CONFIRM BOOKING // they are eligible to book this future session, but need to confirm their booking
  JOINABLE_REQUIRE_EMAIL_VERIFICATION_FIRST: 'joinable/require-email-verification-first', // VERIFY EMAIL     // they are eligible to book this future session, but need to verify their email still
  JOINABLE_FUTURE_SESSION: 'joinable/future-session',                             // BOOK             // they are eligible to book this future session
  JOINABLE_FUTURE_SESSION_REQUEST: 'joinable/future-session-request',             // BOOK             // the session doesn't actually exist, but the user can create+book a host TBD session at the given time
  JOINABLE_HAPPENING_NOW: 'joinable/happening-now',                               // JOIN NOW         // they are eligible to immediately join this session
  JOINABLE_HAPPENING_NOW_NON_USER: 'joinable/happening-now/non-user',             // JOIN NOW         // they are eligible to immediately create a new extremely_unauthed_user and join this session
  // JOINABLE_HAPPENING_NOW_LOADING is deprecated
  JOINABLE_HAPPENING_NOW_LOADING: 'joinable/happening-now/loading',               // JOIN NOW         // they are eligible to immediately join this session
  JOINABLE_WARN_NEW_USERS_LONGER_CHAT_ONLY_SESSION: 'joinable/warn-new-users-longer-chat-only',         // Allow new users to join, but show a warning that it's a longer chat-only session

  NOT_JOINABLE_FULL: 'not-joinable/full',                                         // FULL             // the user is not able to join this event because it is full
  NOT_JOINABLE_HAPPENING_NOW: 'not-joinable/happening-now',                       // HAPPENING NOW    // the user is not able to join this event because its past time
  NOT_JOINABLE_PAST: 'not-joinable/past',                                         // MISSED IT        // the user is not able to join this event because its past time
  NOT_JOINABLE_UPDATE_CARD: 'not-joinable/update-card',                           // UPDATE CARD      // the user is not able to join because they need to update their card
  NOT_JOINABLE_BAD_SUBSCRIPTION_STATE: 'not-joinable/bad-subscription-state',     // SEE PLANS // the user is not able to join because of subscription reasons
  

  HOST_FUTURE_SESSION: 'host/future-session',                                     // HOSTING          // is going to host this session but it has not happened yet
  HOST_HAPPENING_NOW: 'host/happening-now',                                       // START THE SESSION //is hosting an event that is about to happen / is currently happening
  HOST_HAPPENING_NOW_CANCELABLE: 'host/happening-now/cancelable',                 // START THE SESSION //is hosting an event that is about to happen / is currently happening, but is still within the booking/cancelation grace period
  HOST_PAST_SESSION: 'host/past',                                                 // MISSED IT        // hosted this event
}
exports.USER_SESSION_STATUS = USER_SESSION_STATUS

exports.NEW_USER_SESSIONS_THRESHOLD = 6 // Was 5 until December 5, 2023

exports.MONTHLY_INTENTION_ACTIVE_AFTER = dayjs.tz("2022-09-07 06:00:00", "America/Los_Angeles").valueOf()
exports.MONTHLY_INTENTION_ACTIVE_BEFORE = dayjs.tz("2022-10-01 00:00:00", "America/Los_Angeles").valueOf()
// this is what's known as "robust and definitely not silly hardcoded nonsense" code
exports.CURRENTLY_ACTIVE_MONTHLY_CHALLENGE = 'September 2022'

const MONTHLY_CHALLENGE_TRIGGERED_MESSAGE_TYPES = {
  BOOKED_SESSION: 'BOOKED_SESSION',
  REQUESTED_SESSION: 'REQUESTED_SESSION', // unused
  CREATED_SESSION: 'CREATED_SESSION',
  ATTENDED_SESSION: 'ATTENDED_SESSION',
  ATTENDED_SESSION_TOGETHER: 'ATTENDED_SESSION_TOGETHER', // not implemented yet
  JOINED_CHALLENGE_GROUP: 'JOINED_CHALLENGE_GROUP',
  UPDATED_MONTHLY_INTENTION: 'UPDATED_MONTHLY_INTENTION'
}
exports.MONTHLY_CHALLENGE_TRIGGERED_MESSAGE_TYPES = MONTHLY_CHALLENGE_TRIGGERED_MESSAGE_TYPES

exports.HOST_INCENTIVE_SESSIONS_GOAL = 10

exports.NEW_HOST_EVENT_TAG = "New Host"

const ParticipantStatus = {
  UPCOMING: 'upcoming',
  CANCELED: 'canceled',
  COMPLETED: 'completed',
  NO_SHOWED: 'noShowed'
}

exports.ParticipantStatus = ParticipantStatus

const ACTIVE_PAYING_SUBSCRIPTION_STATES = [
  SubscriptionStatus.ACTIVE,
  SubscriptionStatus.ACTIVE_BUT_TRIALING,
  SubscriptionStatus.PAST_DUE, // Still should get incentive
  SubscriptionStatus.PAUSED, // Still should get incentive
]
exports.ACTIVE_PAYING_SUBSCRIPTION_STATES = ACTIVE_PAYING_SUBSCRIPTION_STATES

exports.DEFAULT_INVITE_CODE = 'FloWelcome713'

exports.HELP_EMAIL = "help@flow.club"
exports.SESSION_CREATION_VISIBILITY_MODES = {
  PUBLIC: 'PUBLIC',
  PRIVATE: 'PRIVATE'
}

const GENERAL_DESCRIPTION_EXPLANATION = "- [Small Group] Up to 8 participants and a host.\n\
- [Structured for Accountability] Typical agenda is 5 minutes of briefly sharing your goals with the group, 50 minutes of doing solo, focused work on mute, and 5 minutes of celebrating at the end.\n\
- [Starts and Ends On Time] Sessions always start/end on time. If you show up late, simply use the chat to say hello and share your goals for the session.\n\
- [Work on What Matters to YOU] Flow Club is a supportive, judgment-free zone. Treat it as an opportunity for you to focus on yourself and whatever you need to get done.\n\n\
To book more sessions, reschedule or cancel: https://in.flow.club/upcoming/"
exports.GENERAL_DESCRIPTION_EXPLANATION = GENERAL_DESCRIPTION_EXPLANATION

const GENERAL_DESCRIPTION = `Here's what to expect in a Flow Club:\n${GENERAL_DESCRIPTION_EXPLANATION}`
exports.GENERAL_DESCRIPTION = GENERAL_DESCRIPTION;

const DESCRIPTION_FEEDBACK_FOOTER = "Need help? Have feedback? Email members@flow.club"
exports.DESCRIPTION_FEEDBACK_FOOTER = DESCRIPTION_FEEDBACK_FOOTER;

exports.CALENDAR_ORGANIZER_EMAIL = "concierge@flow.club"
exports.CALENDAR_ORGANIZER_NAME = "Flow Club Concierge"

exports.USER_SESSION_LIST_MODES = {
  UPCOMING: 'UPCOMING',
  PAST: 'PAST'
}

exports.GENERIC_HOST_IMAGE = "https://storage.googleapis.com/flowclub-static/images/generic-host.png"
exports.RICKY_IMAGE = "https://storage.googleapis.com/flowclub-static/images/hosts/ricky.jpg"
exports.DAVID_IMAGE = "https://storage.googleapis.com/flowclub-static/images/hosts/david-t-Z4tljrQdjRbHqdBpscqDbg6CjdG3.png"
exports.HOST_TBD_SESSION_EVENT_TYPE = "Classic"

exports.SESSION_CREATION_MINIMUM_MINUTES_BEFORE_SESSION_START = -5

// minutes after session start after which hosting can be claimed if the original host hasn't joined
exports.NO_SHOWED_HOST_CLAIMING_GRACE_PERIOD = 3 * ONE_MINUTE

exports.DAYS_TO_SHOW_ON_SCHEDULE = 7

exports.MAX_SESSIONS_TO_SHOW_ON_CLUB_PAGE = 25

exports.DATE_FORMAT = "YYYY-MM-DD"

exports.FAVORITE_HOSTS_FILTER = "All Favorite Hosts"

exports.CLUB_MAX_MEMBERS = 10

exports.CLUB_MEMBERSHIP_STATUSES = {
  ACTIVE: 'active',
  INTERESTED: 'interested',
  HOST: 'host',
  PENDING_PAYMENT: 'pending_payment',
  COMPLETED: 'completed',
}

exports.CLUB_MEMBERSHIP_ACTIVE_STATUSES = new Set([
  exports.CLUB_MEMBERSHIP_STATUSES.ACTIVE,
  exports.CLUB_MEMBERSHIP_STATUSES.HOST,
])

exports.CLUB_PAYMENT_TYPES = {
  FREE: 'Free',
  TIPPING_ENCOURAGED: 'Tipping encouraged',
  PAID: 'Paid',
}

exports.CLUB_CLOSES_FOR_SIGNUPS_AFTER = ONE_WEEK

exports.DISPLAY_NAME_DENY_LIST = new Set([
  '🇮🇱', // Israel flag 
  '🇵🇸', // Palestine flag
  '🍉', // Watermelon representing Palestine flag
])

exports.MAX_EMOJIS_IN_DISPLAY_NAME = 7

exports.NAV_SIDEBAR_MIN_WIDTH = 1250

exports.NAV_SIDEBAR_WIDTH = 225

exports.OPEN_SESSION_SETTINGS_OPTIONS = {
  NEW_WINDOW: 'New Window',
  NEW_TAB: 'New Tab',
  CURRENT_TAB: 'Current Tab',
}

exports.MUSIC_STYLES = {
  ALWAYS_MUSIC: 'I always play music',
  SOMETIMES_MUSIC: "I'll play music sometimes",
  NO_MUSIC: 'Bring your own music',
}

exports.SESSION_SMS_REMINDER_TIME_BEFORE_MINUTES = 30;