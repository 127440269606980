import firebase from 'firebase/compat/app';
import { useContext } from 'react';
import { InviteInfoContext } from '../InviteInfoWrapper';
import { useSignupWithoutBooking } from '../fetch/endpoints';
import { useLoginSuccessCallback } from './useLoginSuccessCallback';
import { UserContext } from '../UserProvider';
import { useSendSegmentEvent } from '../wrappers/SegmentProvider';
import * as Sentry from "@sentry/react";
import { useFirebaseAuth, FIREBASE_AUTH_METHODS } from './useFirebaseAuth';
import { toastSuccess } from '../components/utils/toaster';

export const useGoogleSignup = ({ onSuccess = () => { }, onFailure = () => { } }) => {
  const { inviteCode, guestPass } = useContext(InviteInfoContext);
  const { preSignupUserData, setLoginInProgress } = useContext(UserContext);
  const sendSegmentEvent = useSendSegmentEvent();

  const { performFetch: signupWithoutBooking } = useSignupWithoutBooking();
  const setSignupSuccessResult = useLoginSuccessCallback(onSuccess, "signup");
  const setLoggedInSuccessResult = useLoginSuccessCallback(onSuccess, "signup");

  // Helper function to create a Firestore user record
  const createFirestoreUser = async (authUser) => {
    const { displayName, email, uid: userId } = authUser;
    const { success, error } = await signupWithoutBooking({
      displayName,
      email,
      userId,
      inviteCode,
      guestPassId: guestPass ? guestPass.id : null,
      preSignupUserData
    });

    if (success) {
      sendSegmentEvent("Signed up for trial", { authMethod: 'Google Login' });
      setSignupSuccessResult(authUser);
    } else {
      console.error(`Error creating user after successful account creation auth (id ${userId})`);
      console.error(error);
      Sentry.captureMessage("Error creating firestore user after successful account creation auth", {
        level: "warning",
        extra: { error, displayName, email, userId }
      });
      Sentry.captureException(error, { extra: { displayName, email, userId } });
      await firebase.auth().currentUser.delete();
      onFailure(`There was an error creating your account. Please contact help@flow.club`);
      setLoginInProgress(false);
    }
  };

  // Process auth result for initial signup attempt or redirect result
  const processAuthResult = async (result) => {
    sendSegmentEvent("User Signed Up", {
      accountExisted: !result.additionalUserInfo.isNewUser,
      firebaseAuthMethod: FIREBASE_AUTH_METHODS.GOOGLE
    });

    const authUser = result.user;

    if (result.additionalUserInfo.isNewUser) {
      setLoginInProgress(true);
      await createFirestoreUser(authUser);
    } else {
      Sentry.captureMessage("Existing user attempted signup", {
        level: "warning",
        extra: {
          email: result.additionalUserInfo.profile.email,
          userId: authUser.uid
        }
      });
      toastSuccess({
        message: `You already have a Flow Club account using this email (${result.additionalUserInfo.profile.email}). We've logged you in instead.`
      });
      setLoggedInSuccessResult(authUser);
    }
  };

  // Process existing auth user when there's no redirect result
  const processExistingAuthUser = async (authUser, user) => {
    const authCreationTime = new Date(authUser.metadata.creationTime);
    const isRecentlyCreated = (Date.now() - authCreationTime) < 300000; // 5 minutes

    if (isRecentlyCreated && !user) {
      // Recently created auth user with no Firestore record - create the Firestore user
      setLoginInProgress(true);
      await createFirestoreUser(authUser);
    } else {
      // Existing auth user - treat as login
      const syntheticResult = {
        user: authUser,
        additionalUserInfo: {
          isNewUser: false,
          profile: { email: authUser.email }
        }
      };

      await processAuthResult(syntheticResult);
    }
  };

  return useFirebaseAuth({
    authMethod: FIREBASE_AUTH_METHODS.GOOGLE,
    onFailure,
    processAuthResult,
    processExistingAuthUser
  });
};
