/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Button } from 'react-bootstrap';
import { FC_BLUE, FC_DARK_BLUE, FC_IRIS_BLUE } from '../emotionVariables';
import { InterstitialContext } from './WelcomeInterstitial';
import { useContext, useState } from 'react';
import { BUTTON_STYLES, FlowButton } from '../components/flowComponents';
import { useUpdateUser } from '../fetch/endpoints';
import { useSendSegmentEvent } from '../wrappers/SegmentProvider';
import { UserContext } from '../UserProvider';
import { makingTimeForGoalEmojis, makingTimeForGoals } from '../../functions/shared/constants';
import { InviteInfoContext } from '../InviteInfoWrapper';

export const GoalPane = () => {
  const { largeScreenView, goToNextPane } = useContext(InterstitialContext)
  const { inviteValidationInvitedBy: invitedBy } = useContext(InviteInfoContext)
  const wasInvited = invitedBy !== null
  const invitedByName = invitedBy?.name ?? null
  const greetingMessage = wasInvited && invitedByName ? `Join ${invitedByName} and get unstuck in one hour.` : `Get unstuck in one hour. Trusted by thousands of members`

  const sendSegmentEvent = useSendSegmentEvent()

  const { performFetch: updateUser } = useUpdateUser()
  const { user, setPreSignupUserData } = useContext(UserContext)
  const saveGoal = async (goal) => {
    if (user !== null) {
      await updateUser({ updateObject: { makingTimeFor: goal } })
    } else {
      setPreSignupUserData({ makingTimeFor: goal })
    }
  }

  // just used for tracking which button to give a loading state
  const [optionSelected, setOptionSelected] = useState(null)

  const optionClicked = async (option) => {
    sendSegmentEvent("[Goal] Preset Option Clicked", { goal: option })
    setOptionSelected(option)
    await saveGoal(option)
    goToNextPane()
  }

  const goalButtonCss = css`
    height: 48px;
    padding: 10px 16px;
    justify-content: center;
    align-items: center;
    &:hover {
      background-color: ${FC_BLUE};
      color: white;
    }
    &:active, &:focus {
      background-color: ${FC_IRIS_BLUE};
      color: white;
    }
  `

  return (
    <>
      <h2
        css={css`
          color: ${FC_DARK_BLUE};
          margin-bottom: 16px;
          text-align: center;
        `}
      >
        What is something you need to make time for?
      </h2>
      <div
        css={css`
          letter-spacing: 0.15px;
          text-align: center;
          color: ${FC_DARK_BLUE};
          margin-bottom: ${largeScreenView ? 32 : 24}px;
        `}
      >
        {greetingMessage}
      </div>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 8px;
          width: 100%;
        `}
      >
        {makingTimeForGoals.map((option) => {
          const optionLabel = makingTimeForGoalEmojis[option]
            ? `${option} ${makingTimeForGoalEmojis[option]}`
            : option;
          return (
            <FlowButton
              key={option}
              customCss={goalButtonCss}
              onClick={() => optionClicked(option)}
              fillAvailableWidth
              buttonStyle={BUTTON_STYLES.OUTLINE_DARK}
            >
              {optionLabel}
            </FlowButton>
          );
        })}
        <Button
          onClick={
            () => goToNextPane(2) // minor hack to hardcode skipping SpecificGoalPane
          }
          disabled={optionSelected !== null}
          variant="link"
        >
          Do this later
        </Button>
      </div>
    </>
  );
}