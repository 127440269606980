import { useContext, useEffect, useRef, useState } from "react"
import { UserContext } from "../UserProvider"

export const useLoginSuccessCallback = (onSuccess, authType: 'signup' | 'login') => {
  // having to do this through this state/useEffect and not through pure callbacks feels pretty meh
  // but for now not sure what a better alternative is than waiting for UserProvider to actually get the firestore user
  const { user, setLoginInProgress } = useContext(UserContext)

  const [loggedInSuccessResult, setLoggedInSuccessResult] = useState(null)
  const onSuccessCalled = useRef(false)
  useEffect(() => {
    // @ts-ignore
    setLoginInProgress(false)

    // wait until UserProvider's auth.onAuthStateChanged has finished requesting firestore user data, and only fire if login was successful
    if (user !== null && loggedInSuccessResult !== null) {
      if (!onSuccessCalled.current) {
        onSuccess({ user, authType })
        onSuccessCalled.current = true
      }

    }
  }, [user, loggedInSuccessResult])

  const registerLoginSuccess = (loginSuccessResult) => {
    setLoggedInSuccessResult(loginSuccessResult)
  }

  return registerLoginSuccess
}