import firebase from 'firebase/compat/app';
import { useContext, useEffect, useRef } from 'react';
import { translateAuthErrorCodeToMessage } from '../components/utils/auth';
import { UserContext } from '../UserProvider';
import * as Sentry from "@sentry/react";

export const REDIRECT_STORAGE_KEY = 'flowclub_auth_redirect_method';
export const RECENT_AUTH_THRESHOLD_MS = 300000; // 5 minutes in milliseconds
export const FIREBASE_AUTH_METHODS = {
  GOOGLE: 'google',
  EMAIL_PASSWORD: 'email_password'
};

const GOOGLE_PROVIDER = new firebase.auth.GoogleAuthProvider();
GOOGLE_PROVIDER.setCustomParameters({
  prompt: 'select_account',
});

export const authFunctions = {
  [FIREBASE_AUTH_METHODS.GOOGLE]: async (isMobile = false) => {
    if (isMobile) {
      await firebase.auth().signInWithRedirect(GOOGLE_PROVIDER);
      return null;
    } else {
      return await firebase.auth().signInWithPopup(GOOGLE_PROVIDER);
    }
  },
  [FIREBASE_AUTH_METHODS.EMAIL_PASSWORD]: async (...args) =>
    await firebase.auth().signInWithEmailAndPassword(...args)
};

export const useFirebaseAuth = ({
  authMethod,
  onFailure,
  processAuthResult,
  processExistingAuthUser,
}) => {
  const { setLoginInProgress, authUser, user, loadingAuthState } = useContext(UserContext);
  const redirectHandledRef = useRef(false);

  // Handle redirect result from mobile auth
  useEffect(() => {
    if (loadingAuthState) return;
    if (redirectHandledRef.current) return;

    const handleRedirectResult = async () => {
      try {
        const storedAuthMethod = localStorage.getItem(REDIRECT_STORAGE_KEY);

        if (!storedAuthMethod) {
          return;
        }

        // Get the redirect result first
        const redirectResult = await firebase.auth().getRedirectResult();

        // Only clear localStorage AFTER we've checked the redirect result
        if (redirectResult && redirectResult.user) {
          localStorage.removeItem(REDIRECT_STORAGE_KEY);
          redirectHandledRef.current = true;
          await processAuthResult(redirectResult);
          return;
        }

        // If we have an auth user but no redirect result, also clear localStorage
        if (authUser) {
          localStorage.removeItem(REDIRECT_STORAGE_KEY);
          redirectHandledRef.current = true;
          await processExistingAuthUser(authUser, user);
          return;
        }
      } catch (error) {
        localStorage.removeItem(REDIRECT_STORAGE_KEY);
        console.error('Error handling auth redirect:', error);
        Sentry.captureException(error);
        onFailure(translateAuthErrorCodeToMessage(error.code));
        setLoginInProgress(false);
      }
    };

    handleRedirectResult();
  }, [authUser, user, loadingAuthState, processAuthResult, processExistingAuthUser, onFailure, setLoginInProgress]);

  // Function to initiate auth
  const attemptAuthentication = async (...args) => {
    try {
      setLoginInProgress(true);

      if (authMethod === FIREBASE_AUTH_METHODS.GOOGLE) {
        // Detect if user is on mobile
        const isMobile = /Android|iPhone|iPad|iPod/i.test(navigator.userAgent);

        if (isMobile) {
          // Set localStorage BEFORE calling the redirect function
          localStorage.setItem(REDIRECT_STORAGE_KEY, FIREBASE_AUTH_METHODS.GOOGLE);

          // On mobile, use redirect flow
          await firebase.auth().signInWithRedirect(GOOGLE_PROVIDER);
          // Code after this won't execute due to redirect
        } else {
          // On desktop, use popup flow
          const result = await authFunctions[FIREBASE_AUTH_METHODS.GOOGLE](false);
          await processAuthResult(result);
        }
      } else {
        // For non-Google auth methods (like email/password)
        const result = await authFunctions[authMethod](...args);
        await processAuthResult(result);
      }
    } catch (error) {
      console.error('Authentication error:', error);
      Sentry.captureException(error);
      onFailure(translateAuthErrorCodeToMessage(error.code));
      setLoginInProgress(false);
    }
  };

  return attemptAuthentication;
};