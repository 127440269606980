/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from '../../UserProvider';
import { FC_RED, FC_YELLOW, } from '../../emotionVariables';
import { toastError, toastSuccess } from '../../components/utils/toaster';
import { LinkStyledText } from '../../components/flowComponents';
import { getUserIsUnauthed } from '../../../functions/shared/helpers';

export const EmailVerificationBanner = () => {
  const { user, authUser, emailVerified } = useContext(UserContext);

  const [emailVerificationSent, setEmailVerificationSent] = useState(false);
  if (user === null) return null;
  const userIsUnauthed = getUserIsUnauthed(user)
  if (userIsUnauthed || emailVerified !== false) return null;

  const sendEmailVerification = async () => {
    try {
      await authUser.sendEmailVerification();
      setEmailVerificationSent(true);
      toastSuccess({ message: 'Verification email sent. Look for an email from verifications@flow.club in your inbox.' });
    } catch (error) {
      console.error(error);
      toastError({ message: 'Error sending verification email. Please try again or contact help@flow.club.' });
    }
  }

  const VerifyEmailText = () => emailVerificationSent ? <span>verify your email: {user.email}</span> : <LinkStyledText css={css`cursor: pointer; text-decoration: underline;`} onClick={sendEmailVerification}>verify your email: {user.email}</LinkStyledText>;

  return (
    <div css={css`
      background-color: ${FC_YELLOW};
      color: ${FC_RED};
      padding: 0.75rem;
      text-align: center;
    `}>
      You need to <VerifyEmailText />{user.sessions === 0 ? ' before you can book a session' : ''}. <Link to="/settings/account/">Update email</Link>
      </div>
  );
};
