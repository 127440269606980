/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useState, useEffect, useRef } from 'react';
import { Form, Button, InputGroup } from 'react-bootstrap';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { useMediaQuery } from 'react-responsive';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { useSavePhoneNumberAndSendConfirmationCode, useConfirmPhoneNumber, useSendNewPhoneNumberVerificationCode } from '../../fetch/endpoints';
import { toastSuccess, toastError } from '../../components/utils/toaster';
import { SESSION_SMS_REMINDER_TIME_BEFORE_MINUTES } from '../../../functions/shared/constants'
import { useSendSegmentEvent } from '../../wrappers/SegmentProvider';

const phoneUtil = PhoneNumberUtil.getInstance();

const isPhoneValid = (phone) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};

const PhoneNumberRegistration = ({ onComplete, location }) => {
  const sendSegmentEvent = useSendSegmentEvent();
  const [step, setStep] = useState('enterPhone');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isValidPhone, setIsValidPhone] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [canResend, setCanResend] = useState(true);
  const [resendCountdown, setResendCountdown] = useState(0);
  const countdownRef = useRef(null);
  const phoneInputRef = useRef(null);
  const submitLockRef = useRef(false); // Add lock to prevent multiple submissions
  
  const isDesktop = useMediaQuery({ minWidth: 768 });
  const inputHeight = isDesktop ? '38px' : '48px';

  const { performFetch: savePhoneNumber, loading: savingPhone } = useSavePhoneNumberAndSendConfirmationCode();
  const { performFetch: confirmPhone, loading: confirmingPhone } = useConfirmPhoneNumber();
  const { performFetch: resendCode, loading: resendingCode } = useSendNewPhoneNumberVerificationCode();

  useEffect(() => {
    return () => {
      if (countdownRef.current) {
        clearInterval(countdownRef.current);
      }
    };
  }, []);

  // Reset submission lock when loading state changes
  useEffect(() => {
    if (!savingPhone) {
      submitLockRef.current = false;
    }
  }, [savingPhone]);

  useEffect(() => {
    // Auto-submit when 6 digits are entered
    if (verificationCode.length === 6 && !confirmingPhone) {
      handleConfirmCode();
    }
  }, [verificationCode, confirmingPhone]);

  // Validate phone number when it changes
  useEffect(() => {
    if (phoneNumber) {
      setIsValidPhone(isPhoneValid(phoneNumber));
    } else {
      setIsValidPhone(false);
    }
  }, [phoneNumber]);

  const startResendCountdown = () => {
    setCanResend(false);
    setResendCountdown(60);
    
    if (countdownRef.current) {
      clearInterval(countdownRef.current);
    }
    
    countdownRef.current = setInterval(() => {
      setResendCountdown(prev => {
        if (prev <= 1) {
          clearInterval(countdownRef.current);
          setCanResend(true);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  const handleSavePhoneNumber = async () => {
    // Prevent multiple submissions
    if (!isValidPhone || savingPhone || submitLockRef.current) {
      return;
    }

    // Set lock to prevent multiple submissions
    submitLockRef.current = true;

    try {
      await savePhoneNumber({ phoneNumber });
      sendSegmentEvent("Entered phone number", {
        location,
      })
      setStep('enterCode');
      startResendCountdown();
    } catch (err) {
      toastError({ message: err.message || 'Failed to save phone number. Please try again.' });
      submitLockRef.current = false; // Reset lock on error
    }
  };

  const handleConfirmCode = async () => {
    if (!verificationCode || verificationCode.length !== 6 || confirmingPhone) {
      return;
    }

    try {
      const { result } = await confirmPhone({ code: verificationCode });
      if (result && result.isConfirmed) {
        toastSuccess({ message: 'Phone number verified successfully!' });
        sendSegmentEvent("Verified phone number", {
          location,
        })
        onComplete();
      } else {
        toastError({ message: 'Invalid verification code. Please try again.' });
      }
    } catch (err) {
      toastError({ message: err.message || 'Failed to verify code. Please try again.' });
    }
  };

  const handleResendCode = async () => {
    if (!canResend || resendingCode) return;
    
    try {
      await resendCode();
      toastSuccess({ message: 'A new verification code has been sent.' });
      sendSegmentEvent("Resent phone number verification code", {
        location,
      })
      startResendCountdown();
    } catch (err) {
      toastError({ message: err.message || 'Failed to resend verification code. Please try again.' });
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && isValidPhone && !savingPhone && !submitLockRef.current) {
      e.preventDefault();
      handleSavePhoneNumber();
    }
  };

  const phoneInputStyles = css`
  .react-international-phone-input-container {
    height: ${inputHeight};
    display: flex;
  }
  
  .react-international-phone-country-selector-button {
    height: ${inputHeight};
    min-height: ${inputHeight};
    max-height: ${inputHeight};
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    display: flex;
    align-items: center;
    padding: ${isDesktop ? '0.375rem 0.75rem' : '0.5rem 0.75rem'}; 
    box-sizing: border-box;
  }
  
  .react-international-phone-input {
    height: ${inputHeight} !important;
    min-height: ${inputHeight} !important;
    max-height: ${inputHeight} !important;
    box-sizing: border-box;
    /* rest of styles... */
  }
`;


  const renderPhoneInputDesktop = () => (
    <InputGroup className="d-flex">
      <div css={[phoneInputStyles, css`flex: 1; margin: 0; padding: 0;`]}>
        <PhoneInput
          defaultCountry="us"
          preferredCountries={['us', 'ca', 'gb']}
          value={phoneNumber}
          onChange={(phone) => {
            setPhoneNumber(phone);
          }}
          onKeyDown={handleKeyDown}
          international
          inputProps={{
            ref: phoneInputRef
          }}
          buttonStyle={{
            height: inputHeight,
          }}
          inputStyle={{
            width: '100%',
            height: inputHeight,
            fontSize: '1rem',
            fontWeight: '400',
            lineHeight: '1.5',
            color: '#495057',
            backgroundColor: '#fff',
            border: '1px solid #ced4da',
            transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out'
          }}
          disabled={savingPhone}
        />
      </div>
      
      {isValidPhone && (
        <div 
          css={css`
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;
            width: 36px;
            background-color: #f8f9fa;
            border: 1px solid #ced4da;
            border-left: none;
            border-right: none;
            height: ${inputHeight};
          `}
        >
          <span role="img" aria-label="valid phone number" css={css`color: #28a745;`}>✓</span>
        </div>
      )}

      <Button
        variant="primary"
        onClick={handleSavePhoneNumber}
        disabled={!isValidPhone || savingPhone || submitLockRef.current}
        css={css`
          height: ${inputHeight};
          border-radius: 0 0.25rem 0.25rem 0;
          white-space: nowrap;
          display: flex;
          align-items: center;
          margin-left: 10px;
        `}
      >
        {savingPhone ? 'Sending...' : 'Verify'}
      </Button>
    </InputGroup>
  );

  const renderPhoneInputMobile = () => (
    <>
      <div css={[phoneInputStyles, css`position: relative;`]}>
        <PhoneInput
          defaultCountry="us"
          preferredCountries={['us', 'ca', 'gb']}
          value={phoneNumber}
          onChange={(phone) => {
            setPhoneNumber(phone);
          }}
          onKeyDown={handleKeyDown}
          international
          inputProps={{
            ref: phoneInputRef
          }}
          buttonStyle={{
            height: inputHeight,
          }}
          inputStyle={{
            width: '100%',
            height: inputHeight,
            fontSize: '1rem',
            fontWeight: '400',
            lineHeight: '1.5',
            color: '#495057',
            backgroundColor: '#fff',
            border: '1px solid #ced4da',
            transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out'
          }}
          disabled={savingPhone}
        />
        
        {isValidPhone && (
          <div 
            css={css`
              position: absolute;
              right: 10px;
              top: 50%;
              transform: translateY(-50%);
              color: #28a745;
              z-index: 10;
            `}
          >
            <span role="img" aria-label="valid phone number">✓</span>
          </div>
        )}
      </div>

      <Button
        variant="primary"
        onClick={handleSavePhoneNumber}
        disabled={!isValidPhone || savingPhone || submitLockRef.current}
        className="mt-3"
        css={css`
          width: 100%;
          padding: 0.375rem 0.5rem;
          height: ${inputHeight};
        `}
      >
        {savingPhone ? 'Sending...' : 'Send Verification Code'}
      </Button>
    </>
  );

  // Improved verification code input that aligns the lines with the actual numbers
  const renderVerificationCodeInput = () => (
    <div css={css`position: relative; width: 100%; margin-bottom: 10px;`}>
      <input
        type="text"
        inputMode="numeric"
        autoComplete="one-time-code"
        placeholder=""
        value={verificationCode}
        onChange={(e) => setVerificationCode(e.target.value.replace(/[^0-9]/g, '').substring(0, 6))}
        maxLength={6}
        disabled={confirmingPhone}
        css={css`
          width: 100%;
          height: calc(1.5em + 0.75rem + 2px);
          padding: 0.375rem 0.75rem;
          font-size: 1.5rem;
          font-weight: 500;
          text-align: center;
          color: #495057;
          background-color: #fff;
          border: 1px solid #ced4da;
          border-radius: 0.25rem;
          transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
          letter-spacing: 0.5em;
          text-indent: 0.25em;
        `}
      />
    </div>
  );

  return (
    <div css={css`
      margin-top: 20px; 
      margin-bottom: 20px;
      padding: ${isDesktop ? '0' : '0 5px'};
    `}>
      <h5>Get a text reminder</h5>
      <p>We'll text you {SESSION_SMS_REMINDER_TIME_BEFORE_MINUTES} minutes before your 1st session.</p>

      {step === 'enterPhone' ? (
        <div>
          <Form.Group>
            <Form.Label>Your mobile phone number</Form.Label>
            {isDesktop ? renderPhoneInputDesktop() : renderPhoneInputMobile()}
            <Form.Text className="text-muted">
              We'll only use this to send session reminders.
            </Form.Text>
          </Form.Group>
        </div>
      ) : (
        <div>
          <Form.Group>
            <Form.Label>Enter the 6-digit verification code</Form.Label>
            {renderVerificationCodeInput()}
            <Form.Text className="text-muted">
              We sent a verification code to {phoneNumber}
              {' '}
              {canResend ? (
                <span 
                  css={css`
                    color: #0d6efd;
                    cursor: pointer;
                    text-decoration: underline;
                  `}
                  onClick={handleResendCode}
                  role="button"
                >
                  {resendingCode ? 'Sending...' : 'Resend code'}
                </span>
              ) : (
                <span>Resend available in {resendCountdown}s</span>
              )}
            </Form.Text>
          </Form.Group>
          {confirmingPhone && <div className="mt-2">Verifying code...</div>}
        </div>
      )}
    </div>
  );
};

export default PhoneNumberRegistration;
