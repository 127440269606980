/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { toastError } from '../components/utils/toaster';
import { useParticipantIds, useVideoTrack } from '@daily-co/daily-react';
import { FC_DARK_BLUE, FC_LIGHT_BLUE } from '../emotionVariables';

export const Pip = ({ onCanvasMount, dailyVideoElement }) => {
  return (
    <div>
      <canvas css={css`display: none;`} ref={onCanvasMount} width="384" height="276"></canvas>
      <div css={css`visibility: hidden; width: 0px; height: 0px; position: fixed; top: 50vh; left: 50vw;`}>
        <video crossOrigin={'anonymous'} ref={dailyVideoElement} id="video" width="0" height="0" controls autoPlay loop muted />
      </div>
    </div>
  )
}

const PIP_DOCUMENT_STYLE = `
<style>
  body {
    margin: 0;
    background: ${FC_LIGHT_BLUE};
    position: relative;
    overflow: hidden;
    font-family: 'Sato', sans-serif;
  }

  .video-container {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
  }

  canvas {
    width: 100%;
    flex-grow: 1;
    object-fit: cover;
  }

  .text-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    bottom: 60px; /* Leave space for tray */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    text-shadow: 0 2px 8px rgba(0,0,0,0.9), 0 0 4px rgba(0,0,0,0.8);
    z-index: 1;
    pointer-events: none;
  }

  .timer {
    font-size: 52px;
    font-weight: 500;
  }

  .goal {
    font-size: 20px;
    margin-top: 10px;
    max-width: 90%;
    text-align: center;
  }

  .tray-container {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background: ${FC_LIGHT_BLUE};
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    z-index: 2;
    opacity: 1;
    transition: opacity 0.3s ease;
  }

  .tray-button {
    width: 40px;
    height: 40px;
    border-radius: 6px;
    background: #ffffff;
    transition: background-color 0.15s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s ease;
  }

  .tray-button:hover {
    background: linear-gradient(0deg, rgba(210, 221, 236, 0.64) 0%, rgba(210, 221, 236, 0.64) 100%), #FFF;
  }

  .icon {
    width: 44px;
    height: 44px;
    fill: ${FC_DARK_BLUE};
  }
</style>
`;

const CAMERA_ON_SVG = `
<svg class="icon" viewBox="0 0 44 44">
  <path d="M13.5 21C14.8807 21 16 19.8807 16 18.5C16 17.1193 14.8807 16 13.5 16C12.1193 16 11 17.1193 11 18.5C11 19.8807 12.1193 21 13.5 21Z" fill="#2FC6A5" />
  <path fillRule="evenodd" clipRule="evenodd" d="M12 13.25C9.92893 13.25 8.25 14.9289 8.25 17V27C8.25 29.0711 9.92893 30.75 12 30.75H24C26.0711 30.75 27.75 29.0711 27.75 27V25.1036L32.375 27.7738C33.5417 28.4474 35 27.6055 35 26.2583V16.732C35 15.3849 33.5417 14.5429 32.375 15.2165L27.75 17.8867V17C27.75 14.9289 26.0711 13.25 24 13.25H12ZM33.125 26.4748L27.75 23.3716V19.6188L33.125 16.5155C33.2917 16.4193 33.5 16.5396 33.5 16.732V26.2583C33.5 26.4508 33.2917 26.571 33.125 26.4748ZM9.75 17C9.75 15.7574 10.7574 14.75 12 14.75H24C25.2426 14.75 26.25 15.7574 26.25 17V27C26.25 28.2426 25.2426 29.25 24 29.25H12C10.7574 29.25 9.75 28.2426 9.75 27V17Z" fill="#091454" />
  </svg>`;

const CAMERA_OFF_SVG = `
<svg class="icon" viewBox="0 0 44 44">
  <path fillRule="evenodd" clipRule="evenodd" d="M7.46967 35.4697C7.17678 35.1768 7.17678 34.7019 7.46967 34.409L11.2117 30.667C9.51913 30.3049 8.25 28.8007 8.25 27V17C8.25 14.9289 9.92893 13.25 12 13.25H24C25.2598 13.25 26.3746 13.8713 27.0546 14.8241L32.409 9.46967C32.7019 9.17678 33.1768 9.17678 33.4697 9.46967C33.7626 9.76256 33.7626 10.2374 33.4697 10.5303L27.6872 16.3128C27.7284 16.5356 27.75 16.7653 27.75 17V17.8867L32.375 15.2165C33.5417 14.5429 35 15.3849 35 16.732V26.2583C35 27.6055 33.5417 28.4474 32.375 27.7739L27.75 25.1036V27C27.75 29.0711 26.0711 30.75 24 30.75H13.25L8.53033 35.4697C8.23744 35.7626 7.76256 35.7626 7.46967 35.4697ZM26.25 17.75L14.75 29.25H24C25.2426 29.25 26.25 28.2426 26.25 27V17.75ZM27.75 23.2272C27.75 23.3165 27.7976 23.3991 27.875 23.4437L33.125 26.4748C33.2917 26.571 33.5 26.4508 33.5 26.2583V16.732C33.5 16.5396 33.2917 16.4193 33.125 16.5155L27.875 19.5466C27.7976 19.5913 27.75 19.6738 27.75 19.7631V23.2272ZM12.6287 29.25L25.9688 15.9099C25.5849 15.2182 24.8471 14.75 24 14.75H12C10.7574 14.75 9.75 15.7574 9.75 17V27C9.75 28.2426 10.7574 29.25 12 29.25H12.6287Z" fill="#B75C4A" />
</svg>
`;

const MIC_ON_SVG = `
<svg class="icon" viewBox="0 0 44 44">
  <path d="M22 24C23.1046 24 24 23.1046 24 22C24 20.8954 23.1046 20 22 20C20.8954 20 20 20.8954 20 22C20 23.1046 20.8954 24 22 24Z" fill="#2FC6A5" />
  <path fillRule="evenodd" clipRule="evenodd" d="M22 9.25C19.3766 9.25 17.25 11.3766 17.25 14V22C17.25 24.6234 19.3766 26.75 22 26.75C24.6234 26.75 26.75 24.6234 26.75 22V14C26.75 11.3766 24.6234 9.25 22 9.25ZM18.75 14C18.75 12.2051 20.2051 10.75 22 10.75C23.7949 10.75 25.25 12.2051 25.25 14V22C25.25 23.7949 23.7949 25.25 22 25.25C20.2051 25.25 18.75 23.7949 18.75 22V14Z" fill="#000000" />
  <path d="M25 32.25H22.75V29.7142C26.6783 29.337 29.75 26.0272 29.75 22V18C29.75 17.5858 29.4142 17.25 29 17.25C28.5858 17.25 28.25 17.5858 28.25 18V22C28.25 25.4518 25.4518 28.25 22 28.25C18.5482 28.25 15.75 25.4518 15.75 22V18C15.75 17.5858 15.4142 17.25 15 17.25C14.5858 17.25 14.25 17.5858 14.25 18V22C14.25 26.0272 17.3217 29.337 21.25 29.7142V32.25H19C18.5858 32.25 18.25 32.5858 18.25 33C18.25 33.4142 18.5858 33.75 19 33.75H25C25.4142 33.75 25.75 33.4142 25.75 33C25.75 32.5858 25.4142 32.25 25 32.25Z" fill="#091454" />
</svg>
`;

const MIC_OFF_SVG = `
<svg class="icon" viewBox="0 0 44 44">
<path fillRule="evenodd" clipRule="evenodd" d="M8.46967 34.4697C8.17678 34.1768 8.17678 33.7019 8.46967 33.409L15.5623 26.3164C14.7336 25.0828 14.25 23.5978 14.25 22V18C14.25 17.5858 14.5858 17.25 15 17.25C15.4142 17.25 15.75 17.5858 15.75 18V22C15.75 23.1821 16.0782 24.2876 16.6484 25.2303L17.7517 24.127C17.4307 23.4871 17.25 22.7647 17.25 22V14C17.25 11.3766 19.3766 9.25 22 9.25C24.6234 9.25 26.75 11.3766 26.75 14V15.1287L33.409 8.46967C33.7019 8.17678 34.1768 8.17678 34.4697 8.46967C34.7626 8.76256 34.7626 9.23744 34.4697 9.53033L26.75 17.25V22C26.75 24.6234 24.6234 26.75 22 26.75C20.6883 26.75 19.5008 26.2183 18.6412 25.3588L17.5806 26.4194C18.7116 27.5504 20.2741 28.25 22 28.25C25.4518 28.25 28.25 25.4518 28.25 22V18C28.25 17.5858 28.5858 17.25 29 17.25C29.4142 17.25 29.75 17.5858 29.75 18V22C29.75 26.0272 26.6783 29.337 22.75 29.7142V32.25H25C25.4142 32.25 25.75 32.5858 25.75 33C25.75 33.4142 25.4142 33.75 25 33.75H19C18.5858 33.75 18.25 33.4142 18.25 33C18.25 32.5858 18.5858 32.25 19 32.25H21.25V29.7142C19.4093 29.5374 17.7566 28.7167 16.5199 27.4801L9.53033 34.4697C9.23744 34.7626 8.76256 34.7626 8.46967 34.4697ZM22 25.25C21.1025 25.25 20.29 24.8862 19.7019 24.2981L25.25 18.75V22C25.25 23.7949 23.7949 25.25 22 25.25ZM18.9 22.9787L25.25 16.6287V14C25.25 12.2051 23.7949 10.75 22 10.75C20.2051 10.75 18.75 12.2051 18.75 14V22C18.75 22.3411 18.8025 22.6699 18.9 22.9787Z" fill="#B75C4A" />
</svg>
`;


function drawImageCenteredAndScaled(img, ctx) {
  const canvas = ctx.canvas;
  const { videoWidth, videoHeight } = img;
  
  // Define the video area (leaving space for the tray at the bottom)
  const trayHeight = 60;
  const videoAreaHeight = canvas.height - trayHeight;
  
  // Calculate aspect ratios
  const videoAspect = videoWidth / videoHeight;
  const videoAreaAspect = canvas.width / videoAreaHeight;
  
  let drawWidth, drawHeight, offsetX, offsetY;
  
  // Cover the video area while maintaining aspect ratio
  if (videoAspect > videoAreaAspect) {
    // Video is wider than video area
    drawHeight = videoAreaHeight;
    drawWidth = drawHeight * videoAspect;
    offsetX = (canvas.width - drawWidth) / 2;
    offsetY = 0;
  } else {
    // Video is taller than video area
    drawWidth = canvas.width;
    drawHeight = drawWidth / videoAspect;
    offsetX = 0;
    offsetY = (videoAreaHeight - drawHeight) / 2;
  }
  
  // Clear the entire canvas
  ctx.clearRect(0, 0, canvas.width, canvas.height);
  
  // Draw the video in the top portion
  ctx.drawImage(
    img,
    0,
    0,
    videoWidth,
    videoHeight,
    offsetX,
    offsetY,
    drawWidth,
    drawHeight
  );  

}


export const useCreatePipStream = ({
  sessionFinished,
  goalText,
  timerText,
  active,
  userIsHost,
  onDeactivate,
  setPictureInPictureActive,
  toggleCamera,
  toggleMic,
  startLeavingCall,
  callObject,
  sessionWorkingTimeStarted,
  sessionWorkingTimeFinished,
  chatOnly,
}) => {

  const pipGoalLength = 40;
  const hostParticipantId = useParticipantIds({
    filter: ({ owner, tracks, local }) => owner && tracks.video.state === 'playable' && !local
  })[0];

  const nonHostParticipantIds = useParticipantIds({
    sort: 'joined_at',
    filter: ({ owner, tracks, local }) => !owner && tracks.video.state === 'playable' && !local
  });

  const currentTimerText = useRef(timerText)
  currentTimerText.current = timerText

  const currentGoalText = useRef(goalText)
  currentGoalText.current = goalText

  const currentSessionFinished = useRef(sessionFinished)
  currentSessionFinished.current = sessionFinished

  const dailyVideoElementRef = useRef(null)
  const [dailyVideoElementMounted, setDailyVideoElementMounted] = useState(false)
  const dailyVideoElement = (element) => {
    dailyVideoElementRef.current = element
    setDailyVideoElementMounted(true)
  }

  const [dailyParticipantId, setDailyParticipantId] = useState((userIsHost ? nonHostParticipantIds[0] : hostParticipantId) ?? null)
  const videoTrackFromHook = useVideoTrack(dailyParticipantId)
  const videoTrack = dailyParticipantId !== null ? videoTrackFromHook : { state: "Waiting for video connection" }
  const latestVideoTrackState = useRef(null)
  latestVideoTrackState.current = videoTrack.state

  // Detect Safari browser
  const isSafari = useRef(/^((?!chrome|android).)*safari/i.test(navigator.userAgent));

  // Store references to functions and objects
  const callObjectRef = useRef(callObject);
  callObjectRef.current = callObject;

  const toggleCameraRef = useRef(toggleCamera);
  toggleCameraRef.current = toggleCamera;

  const toggleMicRef = useRef(toggleMic);
  toggleMicRef.current = toggleMic;

  const startLeavingCallRef = useRef(startLeavingCall);
  startLeavingCallRef.current = startLeavingCall;

  const shouldShowMicButton = useMemo(() => {
    return !chatOnly && (!sessionWorkingTimeStarted || sessionWorkingTimeFinished);
  }, [chatOnly, sessionWorkingTimeStarted, sessionWorkingTimeFinished]);

  // Store the memoized value in a ref for access in event handlers
  const shouldShowMicButtonRef = useRef(shouldShowMicButton);
  useEffect(() => {
    shouldShowMicButtonRef.current = shouldShowMicButton;
  }, [shouldShowMicButton]);

  useEffect(() => {
    const selectedParticipantId = userIsHost
      ? nonHostParticipantIds[0]
      : (hostParticipantId || nonHostParticipantIds[0]);

    setDailyParticipantId(selectedParticipantId);

    if (videoTrack.state === "playable" && dailyVideoElementMounted) {
      const constraints = videoTrack.track.getConstraints()
      const width = constraints.width !== undefined ? constraints.width.ideal : 0
      const height = constraints.height !== undefined ? constraints.height.ideal : 0
      dailyVideoElementRef.current.width = width
      dailyVideoElementRef.current.height = height
      dailyVideoElementRef.current.srcObject = new MediaStream([videoTrack.track]);
    } else if (dailyVideoElementRef.current) {
      dailyVideoElementRef.current.srcObject = null;
    }
  }, [userIsHost, hostParticipantId, nonHostParticipantIds, videoTrack.state, dailyVideoElementMounted]);

  const latestPipActive = useRef(active)
  latestPipActive.current = active

  const canvasRef = useRef(null)

  // Set up Media Session API for automatic PiP - skip for Safari
  useEffect(() => {
    // Skip Media Session API setup for Safari since it doesn't support the enterpictureinpicture action
    if (isSafari.current) {
      return;
    }

    if ('mediaSession' in navigator && 'MediaMetadata' in window) {
      try {
        navigator.mediaSession.metadata = new window.MediaMetadata({
          title: currentGoalText.current || 'Flow Club Session',
          artist: currentTimerText.current,
          artwork: [
            { src: '/favicon.ico', sizes: '96x96', type: 'image/png' },
          ]
        });

        // Only set the action handler if not in Safari
        navigator.mediaSession.setActionHandler('enterpictureinpicture', async () => {
          if (!latestPipActive.current) {
            if (setPictureInPictureActive) {
              setPictureInPictureActive(true);
            }
            try {
              await startStream();
            } catch (error) {
              // Error handled in startStream
            }
          }
        });
      } catch (error) {
        // Silently handle unsupported action handlers
      }

      // Update metadata periodically
      const updateMetadata = () => {
        if (navigator.mediaSession.metadata) {
          navigator.mediaSession.metadata.title = currentGoalText.current || 'Flow Club Session';
          navigator.mediaSession.metadata.artist = currentTimerText.current;
        }
      };

      const metadataInterval = setInterval(updateMetadata, 1000);

      return () => {
        clearInterval(metadataInterval);
      };
    }
  }, [setPictureInPictureActive]);

  // Draw to canvas for video PiP
  const drawToCanvas = () => {
    const canvasElement = canvasRef.current;
    const canvasCtx = canvasElement !== null ? canvasElement.getContext('2d') : null;

    if (canvasCtx !== null && dailyVideoElementRef.current !== null) {
      // Draw video or background
      if (latestVideoTrackState.current === "playable") {
        drawImageCenteredAndScaled(dailyVideoElementRef.current, canvasCtx);
      } else {
        canvasCtx.fillStyle = FC_DARK_BLUE;
        canvasCtx.fillRect(0, 0, canvasElement.width, canvasElement.height);
      }

      // Set up text styling
      const fontSize = !currentSessionFinished.current ? 52 : 30;
      canvasCtx.font = `${fontSize}px sato`;
      canvasCtx.textAlign = 'center';
      canvasCtx.textBaseline = "middle";
      canvasCtx.fillStyle = '#fff';
      canvasCtx.shadowColor = 'rgba(0, 0, 0, .8)';
      canvasCtx.shadowBlur = 8;

      // Draw timer and goal text
      if (currentGoalText.current !== undefined) {
        canvasCtx.fillText(currentTimerText.current, canvasElement.width / 2, canvasElement.height / 3);
        canvasCtx.font = `20px sato`;
        const text = currentGoalText.current.length > pipGoalLength
          ? currentGoalText.current.substring(0, pipGoalLength - 3) + "..."
          : currentGoalText.current;
        canvasCtx.fillText(text, canvasElement.width / 2, canvasElement.height / 3 + fontSize + 10);
      } else {
        canvasCtx.fillText(currentTimerText.current, canvasElement.width / 2, canvasElement.height / 2);
      }
    }

    // Continue animation loop if PiP is active
    if (latestPipActive.current) {
      requestAnimationFrame(drawToCanvas);
    }
  };

  // Handle canvas mount
  const canvasDrawnTo = useRef(false)
  const onCanvasMount = (canvasElement) => {
    if (!canvasDrawnTo.current) {
      canvasRef.current = canvasElement;
      canvasDrawnTo.current = true;
    }
  };

  // Create video element for PiP stream
  async function createVideoForStream() {
    const video = document.createElement('video');
    video.setAttribute("id", "timerVideo");
    video.muted = true;
    video.srcObject = canvasRef.current.captureStream();
    video.play();
    video.addEventListener('leavepictureinpicture', () => onDeactivate());

    return await new Promise(resolve => {
      video.onloadedmetadata = function () {
        resolve(video)
      };
    });
  }

  // Function to create tray buttons with SVG icons from Icon.js
  const createTrayButton = (type, onClick, active = false, disabled = false) => {
    const button = document.createElement('div');
    button.className = `tray-button ${active ? 'active' : ''} ${disabled ? 'disabled' : ''}`;
    button.onclick = onClick;

    // SVG paths from Icon.js
    let svgContent = '';

    switch (type) {
      case 'camera-on':
        svgContent = CAMERA_ON_SVG;
        break;
      case 'camera-off':
        svgContent = CAMERA_OFF_SVG;
        break;
      case 'mic-on':
        svgContent = MIC_ON_SVG;
        break;
      case 'mic-off':
        svgContent = MIC_OFF_SVG;
        break;
      default:
        svgContent = '';
    }

    button.innerHTML = svgContent;
    return button;
  };


  // Start PiP stream
  const startStream = async () => {
    try {
      // In the startStream function, when creating Document PiP
      if ('documentPictureInPicture' in window) {
        const pipWindow = await window.documentPictureInPicture.requestWindow({
          width: 384,
          height: 276
        });

        // Set up PiP window styling
        pipWindow.document.head.innerHTML = PIP_DOCUMENT_STYLE;

        // Create container for video
        const videoContainer = document.createElement('div');
        videoContainer.className = 'video-container';
        pipWindow.document.body.appendChild(videoContainer);

        // Create a canvas in the PiP window
        const canvas = document.createElement('canvas');
        canvas.width = 384;
        canvas.height = 276;
        videoContainer.appendChild(canvas);

        // Get canvas context for drawing
        const pipCanvasCtx = canvas.getContext('2d');

        // Create text overlay
        const textOverlay = document.createElement('div');
        textOverlay.className = 'text-overlay';
        videoContainer.appendChild(textOverlay);

        // Create timer element
        const timerElement = document.createElement('div');
        timerElement.className = 'timer';
        timerElement.textContent = currentTimerText.current;
        textOverlay.appendChild(timerElement);

        // Create goal element if goal text exists
        let goalElement;
        if (currentGoalText.current) {
          goalElement = document.createElement('div');
          goalElement.className = 'goal';
          goalElement.textContent = currentGoalText.current.length > pipGoalLength
            ? currentGoalText.current.substring(0, pipGoalLength - 3) + "..."
            : currentGoalText.current;
          textOverlay.appendChild(goalElement);
        }

        // Add tray to the PiP window
        const trayContainer = document.createElement('div');
        trayContainer.className = 'tray-container';
        videoContainer.appendChild(trayContainer);

        // Get the current state of camera and mic
        const localParticipant = callObjectRef.current?.participants()?.local;
        const isCameraMuted = localParticipant ? !localParticipant.video : true;
        const isMicMuted = localParticipant ? !localParticipant.audio : true;

        // Add camera toggle button
        const cameraButton = createTrayButton(
          isCameraMuted ? 'camera-off' : 'camera-on',
          () => {
            if (toggleCameraRef.current) {
              toggleCameraRef.current();
            }
          },
          !isCameraMuted
        );
        trayContainer.appendChild(cameraButton);

        let micButton;
        if (shouldShowMicButton) {
          // Add mic toggle button
          micButton = createTrayButton(
            isMicMuted ? 'mic-off' : 'mic-on',
            () => {
              if (toggleMicRef.current) {
                toggleMicRef.current();
              }
            },
            !isMicMuted
          );
          trayContainer.appendChild(micButton);
        }


        // Create a drawing function for the PiP window
        const drawToPipCanvas = () => {
          if (dailyVideoElementRef.current !== null) {
            // Draw video or background
            if (latestVideoTrackState.current === "playable") {
              drawImageCenteredAndScaled(dailyVideoElementRef.current, pipCanvasCtx);
            } else {
              pipCanvasCtx.fillStyle = FC_DARK_BLUE;
              pipCanvasCtx.fillRect(0, 0, canvas.width, canvas.height);
            }

            // Continue drawing if PiP is active
            if (latestPipActive.current && pipWindow && !pipWindow.closed) {
              pipWindow.requestAnimationFrame(drawToPipCanvas);
            }
          }
        };

        // Start the drawing loop
        drawToPipCanvas();

        // Set up message handler to receive updates from main window
        const messageHandler = (event) => {
          // Check if the message is from our application
          if (event.source !== window) return;

          // Update camera button state
          if (event.data.type === 'pip-camera-state-update') {
            const isActive = event.data.active;
            if (isActive) {
              cameraButton.classList.add('active');
              cameraButton.innerHTML = CAMERA_ON_SVG;
            } else {
              cameraButton.classList.remove('active');
              cameraButton.innerHTML = CAMERA_OFF_SVG;
            }
          }

          // Update mic button state
          else if (event.data.type === 'pip-mic-state-update' && micButton) {
            const isActive = event.data.active;
            if (isActive) {
              micButton.classList.add('active');
              micButton.innerHTML = MIC_ON_SVG;
            } else {
              micButton.classList.remove('active');
              micButton.innerHTML = MIC_OFF_SVG;
            }
          }

          // Update timer and goal text
          else if (event.data.type === 'pip-text-update') {
            timerElement.textContent = event.data.timerText;

            if (event.data.goalText && goalElement) {
              const text = event.data.goalText.length > pipGoalLength
                ? event.data.goalText.substring(0, pipGoalLength - 3) + "..."
                : event.data.goalText;
              goalElement.textContent = text;
            }
          }
        };

        pipWindow.addEventListener('message', messageHandler);

        // Update text content periodically
        const updateText = () => {
          if (pipWindow && !pipWindow.closed) {
            timerElement.textContent = currentTimerText.current;
            if (currentGoalText.current && goalElement) {
              const text = currentGoalText.current.length > pipGoalLength
                ? currentGoalText.current.substring(0, pipGoalLength - 3) + "..."
                : currentGoalText.current;
              goalElement.textContent = text;
            }
          }
        };
        const textInterval = setInterval(updateText, 1000);

        // Set up state update interval to sync button states
        const updateButtonStates = () => {
          if (pipWindow && !pipWindow.closed && callObjectRef.current) {
            const localParticipant = callObjectRef.current.participants().local;
            if (localParticipant) {
              const hasVideo = localParticipant.video;
              const hasAudio = localParticipant.audio;

              // Update camera button
              if (hasVideo && !cameraButton.classList.contains('active')) {
                cameraButton.classList.add('active');
                cameraButton.innerHTML = CAMERA_ON_SVG;
              } else if (!hasVideo && cameraButton.classList.contains('active')) {
                cameraButton.classList.remove('active');
                cameraButton.innerHTML = CAMERA_OFF_SVG;
              }
              if (micButton && shouldShowMicButton) {
                // Update mic button
                if (hasAudio && !micButton.classList.contains('active')) {
                  micButton.classList.add('active');
                  micButton.innerHTML = MIC_ON_SVG;
                } else if (!hasAudio && micButton.classList.contains('active')) {
                  micButton.classList.remove('active');
                  micButton.innerHTML = MIC_OFF_SVG;
                }
              }
            }
          }
        };
        const stateInterval = setInterval(updateButtonStates, 500);

        // Handle PiP window close
        pipWindow.addEventListener('pagehide', () => {
          clearInterval(textInterval);
          clearInterval(stateInterval);
          pipWindow.removeEventListener('message', messageHandler);
          onDeactivate();
        });

        return;
      }

      // Video PiP fallback
      const pipSupported = 'pictureInPictureEnabled' in document && document.pictureInPictureEnabled;
      if (!pipSupported) {
        return;
      }

      // Start drawing to canvas
      drawToCanvas();

      // Create video element for PiP
      const videoElement = await createVideoForStream();
      // For non-Safari browsers
      if (document.hasFocus()) {
        await videoElement.requestPictureInPicture();

        // Add auto-PiP on tab away for video PiP
        document.addEventListener('visibilitychange', async () => {
          if (document.visibilityState === 'hidden' && latestPipActive.current) {
            try {
              if (!document.pictureInPictureElement) {
                await videoElement.requestPictureInPicture();
              }
            } catch (error) {
              // Error handled silently
            }
          }
        }, { once: true });
      } else {
        // If document doesn't have focus, wait for user interaction
        const userInteractionHandler = async () => {
          try {
            await videoElement.requestPictureInPicture();
            document.removeEventListener('click', userInteractionHandler);
          } catch (error) {
            // Error handled silently
          }
        };
        document.addEventListener('click', userInteractionHandler);
      }
    } catch (err) {
      toastError({ message: err.message });
      console.error(err);
      throw err;
    }
  };

  // Set up event listeners to update PiP window when camera/mic state changes
  useEffect(() => {
    if (!latestPipActive.current) return;

    const handleCameraChange = (event) => {
      if ('documentPictureInPicture' in window && window.documentPictureInPicture.window) {
        window.documentPictureInPicture.window.postMessage({
          type: 'pip-camera-state-update',
          active: event.participants.local.video
        }, '*');
      }
    };

    const handleMicChange = (event) => {
      if ('documentPictureInPicture' in window && window.documentPictureInPicture.window && shouldShowMicButtonRef.current) {
        window.documentPictureInPicture.window.postMessage({
          type: 'pip-mic-state-update',
          active: event.participants.local.audio
        }, '*');
      }
    };

    const handleTextUpdate = () => {
      if ('documentPictureInPicture' in window && window.documentPictureInPicture.window) {
        window.documentPictureInPicture.window.postMessage({
          type: 'pip-text-update',
          timerText: currentTimerText.current,
          goalText: currentGoalText.current
        }, '*');
      }
    };

    if (callObjectRef.current) {
      callObjectRef.current.on('participant-updated', handleCameraChange);
      callObjectRef.current.on('participant-updated', handleMicChange);

      // Set up timer for text updates
      const textUpdateInterval = setInterval(handleTextUpdate, 1000);

      return () => {
        callObjectRef.current.off('participant-updated', handleCameraChange);
        callObjectRef.current.off('participant-updated', handleMicChange);
        clearInterval(textUpdateInterval);
      };
    }
  }, []);

  return { onCanvasMount, dailyVideoElement, startStream };
};
