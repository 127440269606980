/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import '../Schedule.scss';
import './SessionModal.scss';
import { Link } from 'react-router-dom';
import { Button, Modal, Spinner, Alert } from 'react-bootstrap';
import { useContext, useMemo, useRef, useState, useEffect } from 'react';
import dayjs from 'dayjs'
import { UserContext, usePreferences } from '../../UserProvider';
import { CalendarGoalEntry } from '../../components/CalendarGoalEntry';
import { DarkText } from '../../components/flowComponents';
import PhoneNumberRegistration from './PhoneNumberRegistration';
import { SESSION_SMS_REMINDER_TIME_BEFORE_MINUTES } from '../../../functions/shared/constants';
import { useSendSegmentEvent } from '../../wrappers/SegmentProvider';

const loadingMessages = [
  "Studies show that group flow is more enjoyable than solitary flow. 👯🌊",
  "Putting Do Not Disturb on the universe for you 🎧🪐",
  "Carving out time for real work 🔪⏳",
  "Making Slack go down so you can have peace 💬🔻",
  "Summoning extra energy and creativity for your session ⚡️👩🏻‍🎨",
  "Remember to schedule some breaks throughout the day too!🧘🏻‍♀️⏳",
  "While you wait, take a deep breath. You're doing great! 🧘🏻‍♀️👍",
]

export function BookSessionModalContent({ event, onHide, loading, error }) {
  const { user } = useContext(UserContext)
  const randomLoading = useMemo(() => loadingMessages[Math.floor(Math.random() * loadingMessages.length)], [event]);
  const saveGoalsRef = useRef(null)

  const onHideWrapper = async () => {
    if (loading) {
      return
    }
    if (saveGoalsRef && saveGoalsRef.current) {
      await saveGoalsRef.current()
    }
    onHide()
  }

  return (
    <Modal
      onHide={onHideWrapper}
      show
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton={!loading}>
        <Modal.Title as="h2">
          {
            loading ? "Booking your session..." :
              (error ? "Error booking session" : "You're confirmed!")
          }
        </Modal.Title>
      </Modal.Header>
      {loading ? (
        <Modal.Body>
          <h4>
            <Spinner animation="grow" variant="primary" className="mr-3" />
            {randomLoading}
          </h4>
        </Modal.Body>
      ) : (
        <>
          <Modal.Body>
            {error !== null ?
              <BookingError error={error}/> :
              <BookingSuccess event={event} saveGoalsRef={saveGoalsRef} user={user} />
            }
          </Modal.Body>
          <Modal.Footer className="border-0">
            {error === null && user && user.subscriptionStatus && user.subscriptionStatus.startsWith('trialing') && user.trialEnd ?
              <a href="/plans/">You have {dayjs(user.trialEnd.toDate()).fromNow(true)} left on your free trial.</a>
              : null
            }
            <Button onClick={onHideWrapper}>{error === null ? 'Save and Close' : 'Close'}</Button>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
}

const BookingSuccess = ({ event, saveGoalsRef, user }) => {
  const { disableCalendarInvites } = usePreferences()
  const [phoneVerified, setPhoneVerified] = useState(user?.phoneNumber?.isVerified || false);
  const [showPhoneVerification, setShowPhoneVerification] = useState(false);
  const sendSegmentEvent = useSendSegmentEvent()


  const sessionStartsLaterEnough = useMemo(() => {
    return event?.start ? 
      (dayjs(event.start).diff(dayjs(), 'minute') > SESSION_SMS_REMINDER_TIME_BEFORE_MINUTES) : 
      false;
  }, [event]);

  const shouldShowSmsReminder = useMemo(() => {
    const isFirstTimeUser = user?.sessions === 0;
    return isFirstTimeUser && sessionStartsLaterEnough && phoneVerified;
  }, [user, sessionStartsLaterEnough, phoneVerified]);
    

  useEffect(() => {
     if (user && user.sessions === 0 && 
        (!user.phoneNumber || !user.phoneNumber.isVerified) &&
        sessionStartsLaterEnough) {
      
      setShowPhoneVerification(true);
      sendSegmentEvent("Saw Phone Verification", { location: "After Booking" });
    }
  }, [user, event, sessionStartsLaterEnough]);
  const handlePhoneVerificationComplete = () => {
    setPhoneVerified(true);
    setShowPhoneVerification(false);
  };
  
  if (!event) {
    return <Spinner animation="grow" variant="primary" />
  }
  
  return (
    <>
      {disableCalendarInvites ?
        <div>
          Calendar invites turned off in <Link to="/settings/account">settings</Link>.<br/>
          Access this session from the top of the schedule or from <Link to="/profile/sessions">Sessions</Link>.
        </div>
      :
        <div>
          We'll send you a calendar invite for
          <div>
            {event.start ? dayjs(event.start).format('ddd, MMM D @ h:mma z') : ''}.
          </div>
        </div>
      }
      
      {showPhoneVerification && (
        <PhoneNumberRegistration onComplete={handlePhoneVerificationComplete} location="After Booking" />
      )}
      
      {shouldShowSmsReminder && (
        <Alert variant="success" className="mt-3 mb-3">
          Phone verified! You'll receive a text reminder {SESSION_SMS_REMINDER_TIME_BEFORE_MINUTES} minutes before your session starts.
        </Alert>
      )}
      
      <br />
      <CalendarGoalEntry eventId={event.id} saveGoalsRef={saveGoalsRef} />
    </>
  );
}

const BookingError = ({ error }) => {
  return (
    <div>
      <div css={css`
        color: #A55528;
        background-color: #FFEEE4;
        padding: 12px 16px;
      `}>
        {error}
      </div>
      <DarkText customCss={css`margin-top: 20px;`}>Please try again or <a href="mailto:help@flow.club">email us for help.</a></DarkText>
    </div>
  )
}