/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import { useContext, useState } from "react"
import { Modal, Button } from "react-bootstrap"
import { UserContext } from "../UserProvider"
import { useModal } from '../wrappers/MagnificentlyMunificentModalManager'
import { toastError, toastSuccess } from '../components/utils/toaster'
import { EventCard } from '../Sessions/EventCard';
import { useSendSegmentEvent } from '../wrappers/SegmentProvider';

const VerifyEmailBeforeBookingModal = ({ event, bookSessionClicked, onClose }) => {
  const { user, authUser } = useContext(UserContext)
  const [ booked, setBooked ] = useState(false)
  const [emailVerificationSent, setEmailVerificationSent] = useState(false)
  const sendSegmentEvent = useSendSegmentEvent()

  const sendEmailVerification = async () => {
    try {
      await authUser.sendEmailVerification()
      setEmailVerificationSent(true)
      toastSuccess({ message: 'Verification email sent. Look for an email from verifications@flow.club in your inbox.' })
    } catch (error) {
      console.error(error)
      toastError({ message: 'Error sending verification email. Please try again or contact help@flow.club.' })
    }
  }

  const handleBookSession = async () => {
    sendSegmentEvent("Booked session from email verification modal")
    await bookSessionClicked()
    setBooked(true)
  }

  return (
    <Modal show onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Verify Email to Book</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div css={css`display: flex; flex-direction: column; gap: 16px;`}>
        {!user.emailVerified && (
          <>
            <p>To book sessions, we need to verify your email address ({user.email})</p>
            <div>
              {emailVerificationSent ? (
                <p>✅ Verification email sent! Check your inbox and click the link.</p>
              ) : (
                <Button onClick={sendEmailVerification}>
                  Resend Verification Email
                </Button>
              )}
            </div>
          </>
        )}
        <div css={css`display: flex; flex-direction: column; gap: 16px;`}>
          {user.emailVerified ? (
            <>
              <p>✅ Email verified! You can now book sessions.</p>
              {booked ? (
                <p>🎉 You've booked this session!</p>
              ) :
              <>
                {event !== null && <EventCard event={event} hideButton={true} showDate={true} forceMobileView={true} />}
                <Button onClick={handleBookSession}>Book this session</Button>
              </>}
            </>
          ) : (
            <p>After you've verified your email, come back to this page to book your session.</p>
          )}
        </div>
      </div>
    </Modal.Body>
    </Modal>
  )
}

export const useBookWithEmailVerificationHandler = ({ event = null, bookSessionClicked }) => {
  const { setActiveModal } = useModal()

  const bookSessionWithEmailVerificationClicked = () => {
    setActiveModal(VerifyEmailBeforeBookingModal, {
      event,
      bookSessionClicked,
      onClose: () => setActiveModal(null)
    })
  }

  return bookSessionWithEmailVerificationClicked
}
