/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useContext } from 'react';
import { UserContext, usePreSignupUserDataProperty } from '../UserProvider';

import { GoalPane } from '../Onboarding/GoalPane';
import { SpecificGoalPane } from '../Onboarding/SpecificGoalPane';
import { SegmentProvider } from '../wrappers/SegmentProvider';
import { goalToMakeTimeFor } from '../../functions/shared/constants';
import { NowLaterPane } from '../Onboarding/NowLaterPane';
import { QuotesSidebar, QUOTE_TYPES } from '../Onboarding/QuotesSidebar';
import { FullScreenOnboarding } from '../Onboarding/FullScreenOnboarding';
import { SignupPane } from '../Onboarding/SignupPane';
import { GoalSidebar } from '../Onboarding/GoalSidebar';
import { CelebrationScreen } from '../IntentionOnboarding/CelebrationScreen';

const IntentionOnboardingWrapper = () =>
  <SegmentProvider eventLabel={'Intention'}>
    <IntentionOnboardingContent />
  </SegmentProvider>

const IntentionOnboardingContent = ({ }) => {
  const { user } = useContext(UserContext)
  const makingTimeFor = usePreSignupUserDataProperty('makingTimeFor')
  const makingTimeForText = goalToMakeTimeFor[makingTimeFor]
  const savedSpecificGoal = usePreSignupUserDataProperty('specificGoal')
  const nowLaterTitle = `When would you like to focus on ${makingTimeForText || 'your goal'}?`
  const celebrationTitle = "Book another session"
  const titleOverride = makingTimeForText ? `Sign up to book sessions for ${makingTimeForText}` : null

  const celebrationScreen = {
    name: "CelebrationPane",
    MainContent: <NowLaterPane title={celebrationTitle} key="celebration" showUpcoming />,
    sideDesktopContent: <CelebrationScreen />,
  }
  const stepScreens = [
    {
      name: "GoalPane",
      MainContent: <GoalPane key="goal" />,
      sideDesktopContent:
        makingTimeFor === undefined ? (
          <QuotesSidebar quoteType={QUOTE_TYPES.MEDIA} />
        ) : (
          <GoalSidebar selectedGoal={makingTimeFor} step="goal" />
        ),
      isComplete: () => makingTimeFor !== undefined,
    },
    {
      name: "SpecificGoalPane",
      MainContent: <SpecificGoalPane key="specific-goal" />,
      sideDesktopContent: (
        <GoalSidebar selectedGoal={makingTimeFor} step="specific-goal" />
      ),
      mobileContent: (
        <GoalSidebar selectedGoal={makingTimeFor} step="specific-goal" mobileView />
      ),
      isComplete: () => savedSpecificGoal !== undefined,
    },
    {
      name: "SignupPane",
      MainContent: <SignupPane key='signup' titleOverride={titleOverride} />,
      sideDesktopContent: (
        <QuotesSidebar quoteType={QUOTE_TYPES.USER} />
      ),
      isComplete: () => user !== null,
    },
    {
      name: "NowLaterPane",
      MainContent: <NowLaterPane title={nowLaterTitle} key="now-later" showUpcoming={false} />,
      sideDesktopContent: <QuotesSidebar quoteType={QUOTE_TYPES.USER} />,
      isComplete: () => false,
    },
  ];

  const initialCurrentStep = stepScreens.findIndex(step => !step.isComplete());

  return (
    <FullScreenOnboarding
      stepScreens={stepScreens}
      initialCurrentStep={initialCurrentStep === -1 ?stepScreens.length - 1 : initialCurrentStep}
      celebrationScreen={celebrationScreen}
    />
  )
}

export const IntentionOnboarding = IntentionOnboardingWrapper