import firebase from 'firebase/compat/app';
import { useContext } from 'react';
import { useLoginSuccessCallback } from './useLoginSuccessCallback';
import { UserContext } from '../UserProvider';
import { useSendSegmentEvent } from '../wrappers/SegmentProvider';
import * as Sentry from "@sentry/react";
import { useFirebaseAuth } from './useFirebaseAuth';

export const useLogin = ({ firebaseAuthMethod, onSuccess = () => { }, onFailure = () => { } }) => {
  const { setLoginInProgress } = useContext(UserContext);
  const sendSegmentEvent = useSendSegmentEvent();
  const setLoggedInSuccessResult = useLoginSuccessCallback(onSuccess, "login");

  // Process auth result for initial login attempt or redirect result
  const processAuthResult = async (result) => {
    sendSegmentEvent("User Logged In", {
      accountExisted: !result.additionalUserInfo.isNewUser,
      firebaseAuthMethod
    });

    const authUser = result.user;
    if (result.additionalUserInfo.isNewUser) {
      await firebase.auth().currentUser.delete();
      onFailure(`Sorry, but it doesn't look like you have an account with Flow Club yet (using the email ${result.additionalUserInfo.profile?.email || authUser.email}). Did you mean to sign up?`);
      setLoginInProgress(false);
    } else {
      setLoginInProgress(true);
      await setLoggedInSuccessResult(authUser);
    }
  };

  // Process existing auth user when there's no redirect result
  const processExistingAuthUser = async (authUser, user) => {
    if (user) {
      // We have both authUser and user, just log them in
      setLoginInProgress(true);
      await setLoggedInSuccessResult(authUser);
    } else {
      // We have authUser but no Firestore user
      const authCreationTime = new Date(authUser.metadata.creationTime);
      const isRecentlyCreated = (Date.now() - authCreationTime) < 300000; // 5 minutes

      if (isRecentlyCreated) {
        await firebase.auth().currentUser.delete();
        onFailure(`Sorry, but it doesn't look like you have an account with Flow Club yet. Did you mean to sign up?`);
      } else {
        Sentry.captureMessage("Found an auth user but no user object for an old auth user.", {
          extra: { authUser },
        });
      }
      setLoginInProgress(false);
    }
  };

  return useFirebaseAuth({
    authMethod: firebaseAuthMethod,
    onFailure,
    processAuthResult,
    processExistingAuthUser
  });
};
