import React, {
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import 'firebase/compat/firestore';
import { auth, db } from "./firebase";
import { rsplit } from './utils';
import { getUserHasAdminPermissions } from "../functions/shared/helpers";


export const UserContext = createContext({
  user: null,
  authUser: null,
  loadingAuthState: true,
  setInviteCode: () => {},
  userHasAdminPermissions: false,
  loginInProgress: false,
  setLoginInProgress: () => {},
  emailVerified: null,
});

export const UserProvider = ({ children }) => {
  const [userState, setUserState] = useState({ user: null, authUser: null, emailVerified: null })
  const { user, authUser } = userState
  const [loadingAuthState, setLoadingAuthState] = useState(true);

  // a horrible hack to prevent <ExternalApp> causing redirects in the middle of logging in, because life is suffering and callbacks are a lie
  const [loginInProgress, setLoginInProgress] = useState(false)

  const [adminViewToggledOff, setAdminViewToggledOff] = useState(false)

  useEffect(() => {
    auth.onAuthStateChanged(userAuth => {
      if (userAuth) {
        db.collection('users').doc(userAuth.uid)
        .onSnapshot((userDoc) => {
            // TODO (2021-05-26): Update this to just be displayName
            // After we do a backfill
            if (userDoc.exists) {
              const userData = userDoc.data()
              setUserState({ authUser: userAuth, user: { ...userData, preferences: userData.preferences || {}, uid: userDoc.id, tags: userData.tags ?? [] }, emailVerified: userData.emailVerified })
              localStorage.setItem('userDisplay', userAuth.displayName || userAuth.email);
              if (userData.emailVerified) {
                window.analytics.identify(userDoc.id, {
                  firstName: userData.firstName,
                  lastName: userData.lastName,
                  company: userData.company,
                  location: userData.location,
                  displayName: userData.displayName,
                  emojis: userData.emojis,
                  name: userData.fullName || userData.displayName || userData.email,
                  email: userData.email,
                  sessions: userData.sessions,
                  minutes: userData.minutes,
                  created: userData.created?.toDate().toISOString(),
                  noShows: userData.noShows,
                  canceled: userData.canceled,
                  hostStatus: userData.hostStatus,
                  hostedMinutes: userData.hostedMinutes,
                  hostedSessions: userData.hostedSessions,
                  scoredSessions: userData.scoredSessions,
                  totalFlowScore: userData.totalFlowScore,
                  slug: userData.slug,
                  trialEnd: userData.trialEnd ? userData.trialEnd.toDate().toISOString() : '',
                  subscriptionStatus: userData.subscriptionStatus,
                  upcomingSessions: userData.upcomingSessions,
                  timezone: userData.timezone,
                  tags: userData.tags ? userData.tags.join(', ') : '',
                  invitesLeft: userData.invitesLeft,
                  inviteCodeUsed: userData.inviteCodeUsed,
                  inviteCode: userData.inviteCode,
                  makingTimeFor: userData.makingTimeFor,
                  specificGoal: userData.specificGoal,
                  additionalCalendarEmails: userData.additionalCalendarEmails ? Object.keys(userData.additionalCalendarEmails).join(', ') : '',
                  connectedGoogleCalendar: userData.googleCalendarAuth !== undefined,
                  trialExtensionSubmitted: userData.trialExtensionSubmitted ? userData.trialExtensionSubmitted.toDate().toISOString() : '',
                  trialExtensionMIT: userData.trialExtensionMIT,
                  watchedIntroVideo: userData.watchedIntroVideo,
                  trialLengthDays: userData.trialLengthDays,
                  welcomeType: userData.welcomeType,
                  resumesAt: userData.resumesAt ? userData.resumesAt.toDate().toISOString() : '',
                  canceledAt: userData.canceledAt ? userData.canceledAt.toDate().toISOString() : '',
                  hideDisplayNameEmojis: userData.preferences?.hideDisplayNameEmojis,
                  phone: userData.phoneNumber?.isVerified ? userData.phoneNumber?.value : undefined,
                });
                // This is just for CustomerIO in-app messages
                if (window._cio && window._cio.identify) {
                  window._cio.identify({id: userDoc.id});
                }
              }
            }
            setLoadingAuthState(false);
          });
      } else {
        setUserState({ authUser: null, user: null })
        setLoadingAuthState(false);
        localStorage.removeItem('userDisplay');
      }
    });
  }, []);

  if (user && user.displayName && !user.firstName) {
    [user.firstName, user.lastName] = rsplit(user.displayName, ' ', 1);
  }


  const [preSignupUserData, setPreSignupUserData] = usePreSignupUserData()

  return (
    <UserContext.Provider value={{
      user,
      authUser,
      loadingAuthState,
      loginInProgress,
      setLoginInProgress,
      preSignupUserData,
      setPreSignupUserData,
      adminViewToggledOff,
      setAdminViewToggledOff,
      userHasAdminPermissions: getUserHasAdminPermissions(user) && !adminViewToggledOff,
      emailVerified: userState.emailVerified,
    }}>
      {children}
    </UserContext.Provider>
  );
}
export default UserProvider;  

export const usePreferences = () => {
  const { user } = useContext(UserContext)
  return user !== null ? user.preferences : {}
}

export const getDisplayName = ({ hideDisplayNameEmojis, displayName, emojis }) => {
  return hideDisplayNameEmojis || emojis === undefined ? displayName : `${displayName} ${emojis}`
}

export const useGetDisplayName = (user) => {
  const { hideDisplayNameEmojis } = usePreferences()
  if (user === undefined || user === null) {
    return ''
  }
  const { displayName, emojis } = user
  return getDisplayName({ hideDisplayNameEmojis, displayName, emojis })
}


const usePreSignupUserData = () => {
  const [preSignupUserData, setPreSignupUserData] = useState({})
  useEffect(() => {
    const localStorageData = localStorage.getItem('preSignupUserData')
    if (localStorageData !== null) {
      setPreSignupUserData(JSON.parse(localStorageData))
    }
  }, [])
  const setPreSignupUserDataValues = (newValues) => {
    setPreSignupUserData(existingData => {
      const newData = { ...existingData, ...newValues }
      localStorage.setItem('preSignupUserData', JSON.stringify(newData))
      return newData
    })
  }

  useEffect(() => {
    if (Object.keys(preSignupUserData).length > 0) {
      window.analytics.identify(preSignupUserData)
    }
  }, [preSignupUserData])

  return [preSignupUserData, setPreSignupUserDataValues]
}

// mildly misleading name as it only uses preSignupUserData as a fallback, if the user isn't signed in
export const usePreSignupUserDataProperty = (property) => {
  const { user, preSignupUserData } = useContext(UserContext)
  return user !== null ? user[property] : preSignupUserData[property]
}