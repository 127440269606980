/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FC_BLUE, FC_DARK_BLUE, FC_GREEN, NEUTRAL_60 } from '../emotionVariables';
import { UserContext } from '../UserProvider';
import { BUTTON_STYLES, FlowButton, LightGrayText, LoadingIndicator, Text, TEXT_STYLES } from '../components/flowComponents';
import { getSessionDisplayTimeAndDate } from '../Sessions/SessionUtils';
import { InterstitialContext } from './WelcomeInterstitial';
import { useContext } from 'react';
import { useSendSegmentEvent } from '../wrappers/SegmentProvider';
import { useUserMakingTimeFor } from './useUserMakingTimeFor';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { getImageForHost } from '../../functions/shared/helpers';
import { useModal } from '../wrappers/MagnificentlyMunificentModalManager';
import { LoginSignupModal } from '../Sessions/modals/LoginSignupModal';
import { useBookSessionButtonHandler } from '../bookingLogic/useBookSessionButtonHandler';
import { useBookWithEmailVerificationHandler } from '../bookingLogic/useBookWithEmailVerificationHandler';

export const SessionPane = ({ defaultSession = null, isNow, recommendedSessionLoading = false }) => {

  const { largeScreenView, interstitialState } = useContext(InterstitialContext)
  const recommendedSession = interstitialState !== undefined && interstitialState.recommendedSession ? interstitialState.recommendedSession : defaultSession

  const sessionIsDropInAble = recommendedSession !== null && recommendedSession.start < Date.now() && recommendedSession.end > Date.now()

  const makingTimeFor = useUserMakingTimeFor()

  if (recommendedSession === null) {
    if (recommendedSessionLoading) {
      return <LoadingIndicator />
    } else {
      return <SessionsFullMessage />
    }
  }

  return (
    <div css={css`
      width: ${largeScreenView ? '360px' : '100%'};
      margin: 0 auto;
    `}>
      <h2 css={css`
        color: ${FC_DARK_BLUE};
        margin-bottom: ${largeScreenView ? '54' : '40'}px;
        text-align: center;
      `}>
        Join a co-working session to focus on {makingTimeFor}
      </h2>
      <SessionCard session={recommendedSession} sessionIsDropInAble={sessionIsDropInAble} isNow={isNow} />
    </div>
  )
}

const SessionCard = ({ session, sessionIsDropInAble = false, isNow = false }) => {
  const { largeScreenView, goToNextPane } = useContext(InterstitialContext)
  const history = useHistory()
  const { user } = useContext(UserContext)

  const { timeToDisplay, dateToDisplay } = getSessionDisplayTimeAndDate(session)

  const sendSegmentEvent = useSendSegmentEvent()
  const event = {
    ...session,
    start: new Date(session.start),
    end: new Date(session.end)
  }

  const onSuccessHandler = () => {
    if (sessionIsDropInAble) {
      history.push(`/s/${session.id}`);
    } else {
      goToNextPane()
    }
  };
  
  const bookSessionClicked = useBookSessionButtonHandler(event, onSuccessHandler)
  const bookWithEmailVerification = useBookWithEmailVerificationHandler({ 
    event, 
    bookSessionClicked 
  })

  const sessionCardClicked = (e) => {
    sendSegmentEvent("Session Card Clicked", { sessionIsDropInAble, isNow })
    e.preventDefault()
    if (user?.emailVerified === false) {
      bookWithEmailVerification()
    }
    else {
      bookSessionClicked()
    }
  }

  return (
    <>
      <div css={css`
        width: ${largeScreenView ? '360px' : '100%'};
        margin-bottom: 24px;
      `}>
        <div css={css`
          font-size: 18px;
          line-height: 24px;
          text-align: center;
          letter-spacing: 0.15px;
          color: ${FC_DARK_BLUE};
        `}>
          {sessionIsDropInAble ? 'Join this in-progress session' : isNow ? 'Book this session starting soon' : "We couldn't find any sessions starting right now, but here's the next available session."}
        </div>
      </div>
      <Link to={`/session/${session.id}`} onClick={sessionCardClicked}>
        <div css={css`
          width: ${largeScreenView ? '360px' : '100%'};
          padding: 32px;
          border: 1px solid #E4E4E4;
          box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.12);
          border-radius: 12px;
          cursor: pointer;

          display: flex;
          flex-direction: column;
          align-items: center;

          margin-bottom: ${largeScreenView ? '32' : '24'}px;

          transition: transform .5s cubic-bezier(.39,1.99,.69,1.01);
          &:hover, &:active {
            transform: scale(1.05);
          }
        `}>
          <div css={css`
            font-family: 'Sato';
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.1px;
            color: ${sessionIsDropInAble ? FC_GREEN : FC_BLUE};

            margin-bottom: 20px;
          `}>
            {sessionIsDropInAble ? 'Live Now' : ''}
          </div>
          <LightGrayText customCss={css`
            font-family: 'Sato';
            font-weight: 500;
            font-size: 20px;
            line-height: 20px;

            margin-bottom: 12px;
          `}>
            {timeToDisplay}
          </LightGrayText>
          <LightGrayText customCss={css`
            font-family: 'Sato';
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;

            margin-bottom: ${largeScreenView ? '32' : '16'}px;
          `}>
            {dateToDisplay}
          </LightGrayText>
          <div css={css`display: flex; align-items: center; margin-bottom: 32px;`}>
            <img
              css={css`
                width: ${48}px;
                height: ${48}px;
                border-radius: 50%;
                
                margin-right: 12px;
              `}
              src={getImageForHost(session.hostUser, 'image64')}
              alt={session.hostUser.displayName}
            />
            <div>
              <LightGrayText customCss={css`
                font-weight: 500;
                font-size: 10px;
                line-height: 16px;
                letter-spacing: 1.5px;
                text-transform: uppercase;
                text-align: start;
              `}>Host</LightGrayText>
              <LightGrayText customCss={css`
                line-height: 24px;
                letter-spacing: 0.5px;
                text-align: start;
              `}>{session.hostUser.displayName}</LightGrayText>
            </div>
          </div>
          <FlowButton customCss={css`pointer-events: none;`} fillAvailableWidth buttonStyle={BUTTON_STYLES.OUTLINE_DARK}>{sessionIsDropInAble ? 'Join now' : 'Book Now'}</FlowButton>
        </div>
      </Link>
    </>
  )
}

const SessionsFullMessage = () => {
  return (
    <Text customCss={css`text-align: center; color: ${NEUTRAL_60};`} style={TEXT_STYLES.APP_H1}>
      All of our sessions are currently full. Head to the <Link to="/upcoming">schedule</Link> to request a session at a time that works for you!
    </Text>
  )
}