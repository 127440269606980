
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useContext, useState } from 'react';
import { Button } from 'react-bootstrap';
import { UserContext } from '../UserProvider';
import { FC_ALT_GREEN, FC_IRIS_BLUE } from '../emotionVariables';
import { usePreSignupUserDataProperty } from '../UserProvider';
import { useSendSegmentEvent } from '../wrappers/SegmentProvider';
import { useMediaQuery } from 'react-responsive';
import { Text, TEXT_STYLES } from '../components/flowComponents';
import NowImg from '../TimeOnboarding/now.png'
import LaterImg from '../TimeOnboarding/later.png'
import { TimeOnboardingCard } from '../TimeOnboarding/TimeOnboarding';
import { SessionPane } from '../Onboarding/SessionPane';
import { SelectTimePane } from '../Onboarding/SelectTimePane';
import { useGetNextJoinableSessionForNewUser } from '../fetch/endpoints';
import { useAutomaticallyFetch, useFetchSucceeded } from '../fetch/helpers';
import { UpcomingSessions } from '../HomeOnboarding/HomeHome';
import { useUpcomingEvents } from '../Sessions/SessionUtils';


const CARD_STYLES = {
  NOW: { backgroundColor: FC_ALT_GREEN, image: NowImg, text: 'Now', subtitle: 'Get focused immediately.'},
  LATER: { backgroundColor: FC_IRIS_BLUE, image: LaterImg, text: 'Later', subtitle: 'Schedule your focus time.'},
}

const UpcomingSessionsHelper = () => {
  const { user } = useContext(UserContext)
  const userId = user !== null ? user.uid : null
  const [ upcomingEvents ] = useUpcomingEvents(userId)
  return (
    upcomingEvents.length > 0 ?
    <UpcomingSessions upcomingEvents={upcomingEvents} /> : null
  )
}


export const NowLaterPane = ({ title, showUpcoming }) => {
  const sendSegmentEvent = useSendSegmentEvent()
  const specificGoal = usePreSignupUserDataProperty('specificGoal')
  const mobileView = useMediaQuery({query: '(max-width: 575px)'})
  const { result: nextJoinableSessionResult, fetching: nextJoinableSessionLoading, error: nextJoinableSessionError } = useAutomaticallyFetch(useGetNextJoinableSessionForNewUser, {}, { ignoreAuth: true })
  const { nextSession: nextJoinableSession, isNow } = nextJoinableSessionResult || {}
  const nextJoinableSessionLoaded = useFetchSucceeded(nextJoinableSessionLoading, nextJoinableSessionError)

  const [nowLater, setNowLater] = useState(null)


  const onNowClicked = () => {
    sendSegmentEvent('Clicked Now')
    setNowLater('now')
  }

  const onLaterClicked = () => {
    sendSegmentEvent('Clicked Later')
    setNowLater('later')
  }

  const ChooseNowLater = () => {
    return (
      <div>
        <Text style={TEXT_STYLES.APP_H2} customCss={css`text-align: center; margin: 16px 0px;`}>{title}</Text>
        <Text>You are 1.7x more likely to achieve your goal if you make an external commitment to take action.{specificGoal ? <> Commit to making time for: {specificGoal}</> : ''}</Text>
        <div css={css`
          margin-top: ${mobileView ? 24 : 32}px;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 8px;
          align-self: stretch;
        `}> 
          <TimeOnboardingCard style={CARD_STYLES.NOW} onClick={onNowClicked} />
          <TimeOnboardingCard style={CARD_STYLES.LATER} onClick={onLaterClicked} />
        </div>
      </div>
    )
  }

  return (
  <div css={css`text-align: center;`}>
     {nowLater === null ?
      <>
        {showUpcoming && <UpcomingSessionsHelper />}
        <ChooseNowLater /> 
      </>
      :
      nowLater === 'now' ?
      <div css={css`
        display: flex;
        flex-direction: column;
        gap: 8px;
      `}>
        <SessionPane defaultSession={nextJoinableSession} isNow={isNow} recommendedSessionLoading={!nextJoinableSessionLoaded}/>
        <Button
          onClick={onLaterClicked}
          variant="link"
        >
          Find a time later instead
        </Button>
      </div>
      :
      <SelectTimePane />
      }
  </div>
  )
}